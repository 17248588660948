import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addresses } from "../constants";
import { ethers, BigNumber } from "ethers";
import { IERC20, OlympusStakingv2, StakingHelper, LongStaking, SOhmv2, DaoRewardPool } from "src/typechain";
import ierc20ABIJson from "../abi/IERC20.json";
const ierc20ABI = ierc20ABIJson.abi;
import LongStakingABIJson from "../abi/LongStaking.json";
import { formatUnits } from "ethers/lib/utils";
import sOHMv2Json from "../abi/sOhmv2.json";
import daoPoolJson from "../abi/DaoRewardPool.json";
import { providers } from "@0xsequence/multicall";
import { error } from "./MessagesSlice";
import { Web3Provider } from "@ethersproject/providers";

import { t } from "@lingui/macro";
import axios from 'src/utils/axios';

const networkID = Number(process.env.networkID) || 137;

export const longStakes = [
    {
        displayName: t`60 Days`,
        vestingPeriod: t`60 Days`,
        status: false,
        vestingTerm: 1800 * 24 * 60,
        staked: 0,
        rate: 0.01,// is 0.01%
        extraRate: 0.01,//is 0.01%
        name: 'ls60',
        contract: addresses[networkID as keyof typeof addresses].LONG60_STAKING_ADDRESS,
        allowance: 0,
        paramKey:'LongStake60RewardRatio'
    },
    {
        displayName: t`120 Days`,
        vestingPeriod: t`120 Days`,
        status: false,
        vestingTerm: 1800 * 24 * 120,
        staked: 0,
        rate: 0.01,
        extraRate: 0.01,
        name: 'ls120',
        contract: addresses[networkID as keyof typeof addresses].LONG120_STAKING_ADDRESS,
        allowance: 0,
        paramKey:'LongStake120RewardRatio'
    },
    {
        displayName: t`240 Days`,
        vestingPeriod: t`240 Days`,
        status: false,
        vestingTerm: 1800 * 24 * 240,
        staked: 0,
        rate: 0.01,
        extraRate: 0.01,
        name: 'ls240',
        contract: addresses[networkID as keyof typeof addresses].LONG240_STAKING_ADDRESS,
        allowance: 0,
        paramKey:'LongStake240RewardRatio'
    },
    {
        displayName: t`360 Days`,
        vestingPeriod: t`360 Days`,
        status: false,
        vestingTerm: 1800 * 24 * 360,
        staked: 0,
        rate: 0.01,
        extraRate: 0.01,
        name: 'ls360',
        contract: addresses[networkID as keyof typeof addresses].LONG360_STAKING_ADDRESS,
        allowance: 0,
        paramKey:'LongStake360RewardRatio'
    },
]


// Add interface for stake type
interface ILongStake {
    principal: BigNumber;
    all: BigNumber;
    startTime: BigNumber;
    expiry: BigNumber;
    warmup: BigNumber;
    lastBlock: BigNumber;
    pendingPayout: string;
    longStakeReward: string;
    stakes: any[];
}

export const approve = createAsyncThunk(
    "longStake/approve",
    async ({ provider, address, networkID, stake, revoke }: any, { dispatch }) => {
        try {
            const signer = await provider instanceof Web3Provider?provider.getSigner():provider;
            // const signer = provider;
            const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
            const approveTx = await ohmContract.approve(
                stake.contract,
                revoke ? "0" : ethers.utils.parseUnits("1000000000", "gwei").toString(),
            );
            console.log("approveTx", approveTx);
            await approveTx.wait();
            await dispatch(getLongStakeStatus({ address, networkID, provider }));
        } catch (e) {
            const errorMessage = (e as any)?.message;
            if ((e as any).code == "ACTION_REJECTED") {
                dispatch(error(t`User denied transaction signature.`));
                // dispatch(error((e as any).message));
            } else if (e == "cancel") {
                dispatch(error(t`User denied transaction signature.`));
            }
            else if(errorMessage?.indexOf('操作取消')>=0||errorMessage?.indexOf('User cancel')>=0||errorMessage?.indexOf('Cancelled')>=0){
                dispatch(error(t`User denied transaction signature.`))
            }
            else {
                // dispatch(error((e as any).message));
                dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
            }
            throw e;
        }
    })


export const getLongStakeStatus = createAsyncThunk(
    "longStake/getLongStakeStatus",
    async ({ address, networkID, provider }: any, { dispatch }) => {
        if(!address)return[];
        try {
            const signer = provider;
            const multiProvider = provider instanceof ethers.providers.JsonRpcSigner ? new providers.MulticallProvider(provider.provider) : new providers.MulticallProvider(provider);
            const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, multiProvider) as IERC20;
            const daoPoolContract = new ethers.Contract(addresses[networkID].DAO_POOL_ADDRESS as string, daoPoolJson.abi, multiProvider) as DaoRewardPool;
            const stakes = [];
            for (const stakeOld of longStakes) {

                const stake = Object.assign({}, stakeOld);
                stake.extraRate = await axios.get('/levelparam/'+stakeOld.paramKey).then(ret => {
                    return ret?.data?.value ;  
                })
                console.log('longstake:',stake.contract);
                const longStakingContract = new ethers.Contract(stake.contract as string, LongStakingABIJson.abi, multiProvider) as LongStaking;

                console.log('longstake:addresses[networkID].SOHM_ADDRESS',addresses[networkID].SOHM_ADDRESS);
                console.log('longstake:addresses[networkID].STAKING_ADDRESS',addresses[networkID].STAKING_ADDRESS);
                console.log('longstake address:',address);

                const [status, staked, allowance] = await Promise.all([
                    await longStakingContract.status(),
                    daoPoolContract.getStakedAmount(addresses[networkID].SOHM_ADDRESS, addresses[networkID].STAKING_ADDRESS, stake.contract),
                    ohmContract.allowance(address, stake.contract),
                ])

                console.log('status:', status);
                stake.staked = Number(formatUnits(staked, 9));
                stake.status = status;
                console.log('staked:', stake.displayName, stake.staked);
                stake.allowance = Number(formatUnits(allowance, 9));
                stakes.push(stake);
            }
            return stakes;
        } catch (err) {
            console.error('longstake',err);
            throw err;
        }
    }
)

export const getLongStakes = createAsyncThunk(
    "longStake/getLongStakes",
    async ({ address, networkID, provider }: any, { dispatch }) => {
        const signer = provider;
        const multiProvider = provider instanceof ethers.providers.JsonRpcSigner ? new providers.MulticallProvider(provider.provider) : new providers.MulticallProvider(provider);

        const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2Json.abi, multiProvider) as SOhmv2;

        const stakes = [];
        for (let l = 0; l < longStakes.length; l++) {
            // for (let l = 0; l < 1; l++) {
            const longStakingContract = new ethers.Contract(longStakes[l].contract as string, LongStakingABIJson.abi, multiProvider) as LongStaking;

            const [count, extraIndex] = await Promise.all([
                longStakingContract.getUserStakesCount(address),
                longStakingContract.globalExtraIndex(),
            ])
            console.log('longStakes count:',count,longStakes[l].displayName);
            console.log('longStakes global extraIndex:', extraIndex);
            for (let i = 0; i < Number(count); i++) {
                try {
                    const [longStake, pendingPayout,longStakeReward] = await Promise.all([
                        longStakingContract.stakes(address, i),
                        longStakingContract.pendingPayout(address, i),
                        longStakingContract.extraInterest(address, i),
                    ])
                    const all = await sohmContract.balanceForGons(longStake[1]);
                    console.log('longStakes longStake:', longStake);
                    console.log('longStakes pendingPayout:', pendingPayout);
                    // console.log('longStakes longStakeReward:', longStakeReward);

                    stakes.push({
                        index: i,
                        contract: longStakes[l].contract,
                        type: longStakes[l].displayName,
                        principal: longStake[0],
                        all,
                        startTime: longStake[2],
                        expiry: longStake[3],
                        warmup: longStake[4],
                        lastBlock: longStake[5],
                        extraIndex: longStake[10],
                        pendingPayout: formatUnits(pendingPayout, 9),
                        longStakeReward: formatUnits(longStakeReward, 9),

                    });
                } catch (e) {
                    console.error(e);
                    break;
                }
            }
        }
        console.log('longStake stakes:',stakes);
        return { stakes };
    }

)

export const stakeLong = createAsyncThunk(
    "longStake/stakeLong",
    async ({ address, networkID, provider, quantity, stake }: any, { dispatch }) => {
        const signer = await provider instanceof Web3Provider?provider.getSigner():provider;

        const longStakingContract = new ethers.Contract(stake.contract as string, LongStakingABIJson.abi, signer) as LongStaking;
        const stakeTx = await longStakingContract.stake(ethers.utils.parseUnits(quantity, 9));
        await stakeTx.wait();
        await dispatch(getLongStakes({ address, networkID, provider }));
        await dispatch(getLongStakeStatus({ address, networkID, provider }));
    }
)


export const redeemInterest = createAsyncThunk(
    "longStake/redeemInterest",
    async ({ address, networkID, provider, stake, amount }: any, { dispatch }) => {
        try {
            const signer = await provider instanceof Web3Provider?provider.getSigner():provider;
            console.log('redeemInterest:', stake.index, amount);
            const longStakingContract = new ethers.Contract(stake.contract as string, LongStakingABIJson.abi, signer) as LongStaking;
            const redeemTx = await longStakingContract.claimInterest(stake.index, ethers.utils.parseUnits(''+amount, 9));
            await redeemTx.wait();
            await dispatch(getLongStakes({ address, networkID, provider }));
            await dispatch(getLongStakeStatus({ address, networkID, provider }));
        }
        catch (e) {
            const errorMessage = (e as any)?.message;
            if ((e as any).code == "ACTION_REJECTED") {
                dispatch(error(t`User denied transaction signature.`));
                // dispatch(error((e as any).message));
            } else if (e == "cancel") {
                dispatch(error(t`User denied transaction signature.`));
            }
            else if(errorMessage?.indexOf('操作取消')>=0||errorMessage?.indexOf('User cancel')>=0||errorMessage?.indexOf('Cancelled')>=0){
                dispatch(error(t`User denied transaction signature.`))
            }
            else {
                // dispatch(error((e as any).message));
                dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
            }
            throw e;
        }
    }
)

export const redeemExtraInterest = createAsyncThunk(
    "longStake/redeemExtraInterest",
    async ({ address, networkID, provider, stake, amount }: any, { dispatch }) => {
        try{
            const signer = await provider instanceof Web3Provider?provider.getSigner():provider;
            const longStakingContract = new ethers.Contract(stake.contract as string, LongStakingABIJson.abi, signer) as LongStaking;
            console.log('redeemExtraInterest:',stake);
            console.log('redeemExtraInterest:',stake.index,amount);
            const redeemTx = await longStakingContract.turbineExtraInterest(stake.index, ethers.utils.parseUnits(amount, 9));
            await redeemTx.wait();
            await dispatch(getLongStakes({ address, networkID, provider }));
            await dispatch(getLongStakeStatus({ address, networkID, provider }));
        }
        catch (e) {
            const errorMessage = (e as any)?.message;
            if ((e as any).code == "ACTION_REJECTED") {
                dispatch(error(t`User denied transaction signature.`));
                // dispatch(error((e as any).message));
            } else if (e == "cancel") {
                dispatch(error(t`User denied transaction signature.`));
            }
            else if(errorMessage?.indexOf('操作取消')>=0||errorMessage?.indexOf('User cancel')>=0||errorMessage?.indexOf('Cancelled')>=0){
                dispatch(error(t`User denied transaction signature.`))
            }
            else {
                // dispatch(error((e as any).message));
                dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
            }
            throw e;
        }
    }
)

export const redeemPrincipal = createAsyncThunk(
    "longStake/redeemPrincipal",
    async ({ address, networkID, provider, stake, amount }: any, { dispatch }) => {
        try{
            const signer = await provider instanceof Web3Provider?provider.getSigner():provider;
            const longStakingContract = new ethers.Contract(stake.contract as string, LongStakingABIJson.abi, signer) as LongStaking;
            const redeemTx = await longStakingContract.unstakePrincipal(stake.index);
            await redeemTx.wait();
            await dispatch(getLongStakes({ address, networkID, provider }));
            await dispatch(getLongStakeStatus({ address, networkID, provider }));
        }
        catch (e) {
            const errorMessage = (e as any)?.message;
            if ((e as any).code == "ACTION_REJECTED") {
                dispatch(error(t`User denied transaction signature.`));
                // dispatch(error((e as any).message));
            } else if (e == "cancel") {
                dispatch(error(t`User denied transaction signature.`));
            }
            else if(errorMessage?.indexOf('操作取消')>=0||errorMessage?.indexOf('User cancel')>=0||errorMessage?.indexOf('Cancelled')>=0){
                dispatch(error(t`User denied transaction signature.`))
            }
            else {
                // dispatch(error((e as any).message));
                dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
            }
            throw e;
        }
    }
)


const longStakeSlice = createSlice({
    name: "longStake",
    initialState: {
        loading: false,
        longStakes: [] as ILongStake[],
        stakes: [] as any[],
        allowance: 0,
    },
    reducers: {
        setApproveTx(state, action) {
            state.loading = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(approve.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(approve.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(approve.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getLongStakes.fulfilled, (state, action) => {
                state.loading = false;
                state.longStakes = action.payload.stakes as any[];
            })
            .addCase(getLongStakes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getLongStakes.rejected, (state, action) => {
                state.loading = false;
            }).addCase(stakeLong.fulfilled, (state, action) => {
                state.loading = false;
            }).addCase(stakeLong.pending, (state, action) => {
                state.loading = true;
            }).addCase(stakeLong.rejected, (state, action) => {
                state.loading = false;
            }).addCase(getLongStakeStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.stakes = action.payload as any[];
            }).addCase(getLongStakeStatus.pending, (state, action) => {
                state.loading = true;
            }).addCase(getLongStakeStatus.rejected, (state, action) => {
                state.loading = false;
            }).addCase(redeemInterest.fulfilled, (state, action) => {
                state.loading = false;
            }).addCase(redeemInterest.pending, (state, action) => {
                state.loading = true;
            }).addCase(redeemInterest.rejected, (state, action) => {
                state.loading = false;
            }).addCase(redeemExtraInterest.fulfilled, (state, action) => {
                state.loading = false;
            }).addCase(redeemExtraInterest.pending, (state, action) => {
                state.loading = true;
            }).addCase(redeemExtraInterest.rejected, (state, action) => {
                state.loading = false;
                console.log('redeemExtraInterest.rejected:', action.error);
            }).addCase(redeemPrincipal.fulfilled, (state, action) => {
                state.loading = false;
            }).addCase(redeemPrincipal.pending, (state, action) => {
                state.loading = true;
            }).addCase(redeemPrincipal.rejected, (state, action) => {
                state.loading = false;
            })
    }
})


export default longStakeSlice.reducer;
