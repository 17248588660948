import { Box, FormControl, InputLabel, OutlinedInput, InputAdornment, Button, Paper, Backdrop, Grid, Fade, Link, SvgIcon, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Trans, t } from "@lingui/macro";
import { useAppSelector, useMobile, useWeb3Context } from 'src/hooks';
import { useState, useMemo } from "react";
import { Typography } from "@mui/material";

import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { NavLink, useHistory } from "react-router-dom";

import { ReactComponent as XIcon } from "src/assets/icons/x.svg";
import { stakeLong, approve } from "src/slices/LongStakeSlice";
import { useDispatch } from "react-redux";

function toFixedFloor(num: number, decimals: number) {
    const factor = 10 ** decimals;
    return (Math.floor(num * factor) / factor).toFixed(decimals);
}

export const LongTermStakeExec = ({ stake }: { stake: any }) => {
    const [quantity, setQuantity] = useState('0');
    const ohmBalance = useAppSelector(state => state.account.balances.ohm);
    const isSmallScreen = useMobile();
    const { address } = useWeb3Context();
    const isLoading = useAppSelector((state: any) => state.longStake.loading);
    console.log('isLoading:', isLoading);
    let history = useHistory();

    const stakes = useAppSelector((state: any) => state.longStake.stakes);
    const currentStake = useMemo(() => {
        if(stakes){
            return stakes.find((cs: any) => cs.contract === stake.contract);
        }
        return null;
    }, [stakes]);
    console.log('currentStake:', currentStake);

    const {chainID, provider} = useWeb3Context();
    const dispatch = useDispatch();

    const pendingTransactions = useAppSelector(state => {
        return state.pendingTransactions;
    });

    const handleOnchange = (e: any) => {
        setQuantity(e.target.value);
    }

    const setMax = () => {
        setQuantity(toFixedFloor(Number(ohmBalance),4));
    }


    const handleStake = async () => {
        await dispatch(stakeLong({ address, networkID: chainID, provider, quantity, stake }));
        history.goBack();
    }
    const handleApprove = () => {
        dispatch(approve({ address, networkID: chainID, provider, stake }));
    }

    const handleRevokeApprove = () => {
        dispatch(approve({ address, networkID: chainID, provider, stake, revoke:true }));
    }

    const hasAllowance = () => {
        return Number(currentStake?.allowance) && !isNaN(Number(currentStake?.allowance)) && Number(currentStake?.allowance) > Number(quantity);
    }

    return (
        <Fade in={true} mountOnEnter unmountOnExit>
            <Grid container id="bond-view">
                <Backdrop
                    open={true}
                    sx={{
                        px: isSmallScreen ? 1.875 : 0,
                    }}
                >
                    <Fade in={true}>
                        <Paper
                            className="ohm-card ohm-modal"
                            sx={{
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                border: isSmallScreen ? "1px solid #434952" : "2px solid #434952",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                                px: isSmallScreen ? 2.5 : 3.75,
                                py: 2.5,
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="h6" sx={{ color: "#c7c8cc", }}>
                                    {t`Long Term Stake`}
                                </Typography>
                                <Link component={NavLink} to="#" className="cancel-bond" onClick={() => history.goBack()}>
                                    <SvgIcon sx={{ color: "#C7C8CC" }} component={XIcon} />
                                </Link>
                            </Box>
                            <Box>
                                <Typography variant="h6" sx={{ color: "#c7c8cc", }}>
                                    {stake.displayName}
                                </Typography>
                            </Box>
                            <Box sx={{ mt:2, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography  sx={{ color: "#c7c8cc", }}>
                                    <Trans>$LGNS Balance</Trans>: 
                                </Typography>
                                <Typography sx={{ color: "#c7c8cc", ml:1 }}>
                                    {toFixedFloor(Number(ohmBalance),4)}
                                </Typography>
                            </Box>
                            <Box>
                                <Box sx={{ m: 2, p: 2, mt: 4 }}>
                                    <FormControl
                                        className="ohm-input"
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        sx={{
                                            height: "40px",
                                            m: "0px !important",
                                            mr: "15px !important",
                                            mb: isSmallScreen ? "20px !important" : 0,
                                        }}
                                    >
                                        <InputLabel
                                            htmlFor="outlined-adornment-amount"
                                            sx={{
                                                top: "-7px",
                                                color: "#C7C8CC",
                                                fontFamily: "SF Pro Display",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }}
                                        >
                                            <Trans>Amount</Trans>
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            type="number"
                                            sx={{
                                                width: "100%",
                                                height: "42px",
                                                borderRadius: "60px",
                                                border: "1px solid rgba(138, 147, 160, 0.50)",
                                                background: "#3C3F48",
                                                color: "#c7c8cc",
                                                // mb: 1,
                                                boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                                                '& input[type="number"]': {
                                                    py: 0,
                                                },
                                            }}
                                            value={quantity}
                                            onChange={handleOnchange}
                                            // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            // labelWidth={55}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Button
                                                        variant="text"
                                                        onClick={setMax}
                                                        sx={{
                                                            color: "#B50C05",
                                                            fontFamily: "Euclid Square",
                                                            fontSize: "16px",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        <Trans>Max</Trans>
                                                    </Button>

                                                </InputAdornment>
                                            }
                                            slotProps={{root:{'aria-hidden': undefined}}}
                                        />
                                    </FormControl>
                                    <Box>
                                        {(address && hasAllowance()) ? (
                                            <Box
                                                sx={{
                                                    p: isSmallScreen ? 2.5 : 0,
                                                }}
                                            >
                                                <LoadingButton
                                                    className="stake-button main-btn"
                                                    variant="contained"
                                                    disabled={isLoading || isPendingTxn(pendingTransactions, "staking")}
                                                    loading={isLoading || isPendingTxn(pendingTransactions, "staking")}
                                                    loadingPosition="end"
                                                    // endIcon={<CircularProgress size={20}/>}
                                                    endIcon = {<></>}
                                                    onClick={handleStake}
                                                    sx={{
                                                        // width: "100%",
                                                        borderRadius: "30px",
                                                        border: "1.3px solid #B50C05",
                                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                        color: "#FFFFFF",
                                                        fontFamily: "AGaramond LT",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        mb: isSmallScreen ? "20px !important" : 0,
                                                        overflow: "hidden",
                                                        "&:hover": {
                                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                        },
                                                    }}
                                                >
                                                    {txnButtonText(pendingTransactions, "staking", t`Stake LGNS`)}
                                                </LoadingButton>
                                                {/* <LoadingButton
                                                    className="stake-button"
                                                    variant="contained"
                                                    disabled={isLoading || isPendingTxn(pendingTransactions, "approve_staking")}
                                                    loading={isLoading || isPendingTxn(pendingTransactions, "approve_staking")}
                                                    loadingPosition="end"
                                                    // endIcon={<CircularProgress size={20}/>}
                                                    endIcon = {<></>}
                                                    onClick={handleRevokeApprove}
                                                    sx={{
                                                        borderRadius: "30px",
                                                        border: "1.3px solid #B50C05",
                                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                        color: "#FFFFFF",
                                                        fontFamily: "AGaramond LT",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        mb: isSmallScreen ? "20px !important" : 0,
                                                        "&:hover": {
                                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                        },
                                                    }}
                                                >
                                                    {txnButtonText(pendingTransactions, "approve_staking", t`Revoke`)}
                                                </LoadingButton> */}
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    p: isSmallScreen ? 2.5 : 0,
                                                }}
                                            >
                                                <LoadingButton
                                                    className="stake-button"
                                                    variant="contained"
                                                    disabled={isLoading || isPendingTxn(pendingTransactions, "approve_staking")}
                                                    loading={isLoading || isPendingTxn(pendingTransactions, "approve_staking")}
                                                    loadingPosition="end"
                                                    // endIcon={<CircularProgress size={20}/>}
                                                    endIcon = {<></>}
                                                    onClick={handleApprove}
                                                    sx={{
                                                        borderRadius: "30px",
                                                        border: "1.3px solid #B50C05",
                                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                        color: "#FFFFFF",
                                                        fontFamily: "AGaramond LT",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        mb: isSmallScreen ? "20px !important" : 0,
                                                        "&:hover": {
                                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                        },
                                                    }}
                                                >
                                                    {txnButtonText(pendingTransactions, "approve_staking", t`Approve`)}
                                                </LoadingButton>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Fade>
                </Backdrop>
            </Grid>

        </Fade >

    )
}