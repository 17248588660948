import { t, Trans } from "@lingui/macro";
import { QueryClient, QueryClientProvider } from "react-query";
import {
    Box,
    Paper,
    Zoom,
    Typography,
    Button,
    OutlinedInput,
    InputAdornment,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    TextField,
    Skeleton,
    Grid,
    Modal,
    FormControl,
    Checkbox,
    LinearProgress,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { trim, prettyVestingPeriod } from "src/helpers";

import NoData from "src/assets/images/noData.svg";
import { useDispatch } from "react-redux";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import { useAppSelector } from "src/hooks";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useMobile } from "src/hooks";

import { PullToRefresh } from "src/components/PullToRefresh";

import { getStakeExInfo, getStakeExData, exStake, exStakeApprove, claimNFTShard, getOwnedNFT, getOwnedNFTShard, forgeNFT, approveArtisan, exStakeRedeem } from 'src/slices/StakeExSlice';
import { getVoteSession, voteApprove, transactVote , voteWithdraw} from 'src/slices/DaoSlice';

import CountDownTimer from "src/components/CountDownTimer";


const queryClient = new QueryClient();

const DaoVote = () => {
    const dispatch = useDispatch();
    // const [stakeExList, setStakeExList] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const { provider, address, chainID, connected, connect } = useWeb3Context();

    const [amount, setAmount] = useState("0");

    const voteSession = useAppSelector<any>(state => state.dao);
    const ohmBalance = useAppSelector(state => {
        return state.account.balances && state.account.balances.ohm;
    });

    const { isSmallScreen } = useMobile();

    const pendingTransactions = useAppSelector(state => {
        return state.pendingTransactions;
    });

    const handleStake = async () => {
        await dispatch(exStake({ address, provider, networkID: chainID, quantity }));
        await dispatch(getStakeExData({ address, provider, networkID: chainID }));
    }

    const handleApprove = async () => {
        await dispatch(exStakeApprove({ address, provider, networkID: chainID, quantity }));
        await dispatch(getStakeExData({ address, provider, networkID: chainID }));
    }

    const handleRedeem = async (id: any) => {
        await dispatch(exStakeRedeem({ address, provider, networkID: chainID, id }));
        await dispatch(getStakeExData({ address, provider, networkID: chainID }));
    }

    const handleVoteApprove = async () => {
        await dispatch(voteApprove({ address, provider, networkID: chainID }));
        // await dispatch(getVoteSession({ provider, address, networkID: chainID }));
    }

    const handleVoteSupport = async () => {
        await dispatch(transactVote({ address, provider, networkID: chainID, vote: 1, amount }));
        // await dispatch(getVoteSession({ provider, address, networkID: chainID }));
    }

    const handleVoteAgainst = async () => {
        await dispatch(transactVote({ address, provider, networkID: chainID, vote: 2, amount }));
        // await dispatch(getVoteSession({ provider, address, networkID: chainID }));
    }

    const setMax = () => {
        setAmount(ohmBalance || "0");
    }

    const supportRatio = useMemo(() => {
        if (isNaN(voteSession?.supportVotes) || isNaN(voteSession?.againstVotes)) return 0;
        if (voteSession?.supportVotes + voteSession?.againstVotes === 0) return 0;
        return voteSession?.supportVotes / (voteSession?.supportVotes + voteSession?.againstVotes) * 100;
    }, [voteSession?.supportVotes, voteSession?.againstVotes]);

    const againstRatio = useMemo(() => {
        if (isNaN(voteSession?.supportVotes) || isNaN(voteSession?.againstVotes)) return 0;
        if (voteSession?.supportVotes + voteSession?.againstVotes === 0) return 0;
        return voteSession?.againstVotes / (voteSession?.supportVotes + voteSession?.againstVotes) * 100;
    }, [voteSession?.supportVotes, voteSession?.againstVotes]);

    const targetDate = useMemo(() => {
        if (voteSession?.id > 0) {
            if (new Date().valueOf() > (voteSession?.startTime) * 1000) {
                return new Date((voteSession?.startTime + voteSession?.period) * 1000);
            }
            else {
                return new Date((voteSession?.startTime) * 1000);
            }
        }
        return null;
    }, [voteSession]);

    const isVoteActive = useMemo(() => {
        if (voteSession?.id > 0) {
            return new Date().valueOf() > (voteSession?.startTime) * 1000 && new Date().valueOf() < (voteSession?.startTime + voteSession?.period) * 1000;
        }
        return false;
    }, [voteSession])

    const isVoteEnded = useMemo(() => {
        if (voteSession?.id > 0) {
            return new Date().valueOf() > (voteSession?.startTime + voteSession?.period) * 1000;
        }
        return false;
    }, [voteSession])

    useEffect(() => {
        dispatch(getVoteSession({ provider, address, networkID: chainID }))
    }, [address]);

    const handleVoteWithdraw = async () => {
        dispatch(voteWithdraw({provider, address, networkID: chainID, voteId:voteSession?.id}))
    }

    return (
        <QueryClientProvider client={queryClient}>
            <PullToRefresh onRefresh={async () => {
                return new Promise((resolve) => { setTimeout(() => resolve(true), 500) })
            }}>
                <Zoom in={true} timeout={1000}>
                    <Paper
                        className="ohm-card linear-card"
                        sx={{
                            m: 2,
                            mb: isSmallScreen ? 1.875 : 3.125,
                            p: 1,
                            width: { xs: "calc(100vw - 32px)", md: "50vw" }
                        }}
                    >

                        <Box
                            display="flex"
                            flexDirection={"column"}
                            alignItems="center"
                            sx={{
                                width: { xs: "345px", md: "50vw" },
                                pt: isSmallScreen ? 2.5 : 0,
                                px: isSmallScreen ? 1.875 : 0,
                            }}
                        >
                            <Box>
                                <Typography variant="h1"
                                    sx={{
                                        color: "#c1c2c8",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "24px" : "32px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        m: 2
                                    }}
                                ><Trans>Dao Vote</Trans></Typography>
                                {voteSession?.id > 0 && <Typography variant="body1" sx={{
                                    color: "white",
                                    fontFamily: "AGaramond LT",
                                    fontSize: isSmallScreen ? "16px" : "26px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                }}> {voteSession?.proposal}</Typography>}
                            </Box>

                            <Box>
                                {targetDate && <CountDownTimer direction="column" onReachEnd={() => { }} targetDate={targetDate} />}
                            </Box>
                            {isVoteActive && <Box sx={{ mt: 4 }}>
                                <FormControl
                                    className="ohm-input"
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                        px: isSmallScreen ? 2.5 : 0,
                                        "&.ohm-input": {
                                            m: isSmallScreen ? "0 !important" : 0,
                                            mb: isSmallScreen ? "20px !important" : 0,
                                        },
                                    }}
                                >
                                    <OutlinedInput
                                        id="amount-input"
                                        type="number"
                                        placeholder={t`Enter an amount`}
                                        className="stake-input"
                                        value={amount}
                                        onChange={e => setAmount(e.target.value)}
                                        sx={{
                                            height: "42px",
                                            borderRadius: "60px",
                                            border: "1px solid rgba(138, 147, 160, 0.50)",
                                            background: "#3C3F48",
                                            color: "#c7c8cc",
                                            boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Button
                                                    sx={{
                                                        color: "#B50C05",
                                                        textAlign: "right",
                                                        fontFamily: "Euclid Square",
                                                        fontSize: "16px",
                                                        fontStyle: "normal",
                                                        fontWeight: "400",
                                                    }}
                                                    variant="text"
                                                    onClick={setMax}
                                                    color="inherit"
                                                >
                                                    <Trans>Max</Trans>
                                                </Button>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Box>}

                            {<Box sx={{mt:2}}>
                                <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                                    { <LoadingButton
                                        sx={{
                                            width: "120px",
                                            // height: "40px",

                                            borderRadius: "30px",
                                            border: "1.3px solid #B50C05",
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                            color: "#FFFFFF",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            mb: isSmallScreen ? "20px !important" : 0,
                                            "&:hover": {
                                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                            },
                                        }}
                                        variant="contained"
                                        onClick={handleVoteSupport}
                                        disabled={!isVoteActive||Number(amount)<=0||Number(voteSession?.allowance || 0) <= 0 || isPendingTxn(pendingTransactions, "vote") || voteSession?.loading || !connected}
                                        loading={isPendingTxn(pendingTransactions, "vote")}
                                    >
                                        <Trans>Support</Trans>
                                    </LoadingButton>}
                                    <Box  sx={{ mt: '-25px' }}>
                                        <Typography variant="body1" sx={{
                                            color: "#c1c2c8",
                                            fontFamily: "AGaramond LT",
                                            fontSize: isSmallScreen ? "16px" : "26px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}>
                                            {voteSession?.supportVotes?.toFixed(2)}LGNS
                                        </Typography>
                                        <LinearProgress variant="determinate" sx={{ width: "160px", height: "8px" }} value={supportRatio} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center",minHeight:"60px" }}>
                                    {<LoadingButton
                                        sx={{
                                            width: "120px",
                                            // height: "40px",

                                            borderRadius: "30px",
                                            border: "1.3px solid #B50C05",
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                            color: "#FFFFFF",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            mb: isSmallScreen ? "20px !important" : 0,
                                            "&:hover": {
                                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                            },
                                        }}
                                        variant="contained"
                                        onClick={handleVoteAgainst}
                                        disabled={!isVoteActive||Number(amount)<=0||Number(voteSession?.allowance || 0) <= 0 || isPendingTxn(pendingTransactions, "vote") || voteSession?.loading || !connected}
                                        loading={isPendingTxn(pendingTransactions, "vote")}
                                    >
                                        <Trans>Against</Trans>
                                    </LoadingButton>}
                                    <Box sx={{ mt: '-25px' }}>
                                        <Typography variant="body1" sx={{
                                            color: "#c1c2c8",
                                            fontFamily: "AGaramond LT",
                                            fontSize: isSmallScreen ? "16px" : "26px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}>
                                            {voteSession?.againstVotes?.toFixed(2)}LGNS
                                        </Typography>
                                        <LinearProgress variant="determinate" sx={{ width: "160px", height: "8px" }} value={againstRatio} />
                                    </Box>
                                </Box>


                            </Box>}
                            {
                                isVoteEnded&&(Number(voteSession?.userVotes)>0)&&!voteSession?.withdrawn&&<Box>
                                    <LoadingButton
                                        sx={{
                                            width: "120px",
                                            // height: "40px",

                                            borderRadius: "30px",
                                            border: "1.3px solid #B50C05",
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                            color: "#FFFFFF",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            mb: isSmallScreen ? "20px !important" : 0,
                                            "&:hover": {
                                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                            },
                                        }}
                                        variant="contained"
                                        onClick={handleVoteWithdraw}
                                        disabled={Number(voteSession?.allowance || 0) <= 0 || isPendingTxn(pendingTransactions, "vote_withdraw") || voteSession?.loading || !connected}
                                        loading={isPendingTxn(pendingTransactions, "vote_withdraw")}
                                    >
                                        <Trans>Withdraw</Trans>
                                    </LoadingButton>
                                </Box>
                            }

                            {
                               isVoteActive&& Number(voteSession?.allowance || 0) <= 0 && <Box>
                                    <LoadingButton
                                        // className="stake-button"
                                        variant="contained"
                                        onClick={handleVoteApprove}
                                        disabled={isPendingTxn(pendingTransactions, "vote_approve") || voteSession?.loading || !connected}
                                        loading={isPendingTxn(pendingTransactions, "vote_approve")}
                                        // loadingPosition="end"
                                        sx={{
                                            // width: "100%",
                                            // height: "40px",

                                            borderRadius: "30px",
                                            border: "1.3px solid #B50C05",
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                            color: "#FFFFFF",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "18px",
                                            fontWeight: "700",
                                            mb: isSmallScreen ? "20px !important" : 0,
                                            "&:hover": {
                                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                            },
                                        }}
                                    >
                                        <Trans>Approve</Trans>
                                    </LoadingButton>
                                </Box>
                            }
                            <Box>

                                {
                                    voteSession?.id > 0 ? (
                                        <Box>
                                            <Typography variant="h6" sx={{
                                                color: "#c1c2c8",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "18px" : "26px",
                                                fontStyle: "normal",
                                                fontWeight: "700",
                                                mt: 2
                                            }}>
                                                <Trans>Summary</Trans>
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                color: "#c1c2c8",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "16px" : "26px",
                                                fontStyle: "normal",
                                                fontWeight: "400",

                                            }}>
                                                <Trans>vote003_summary</Trans>
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                color: "#c1c2c8",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "18px" : "26px",
                                                fontStyle: "normal",
                                                fontWeight: "700",
                                                mt: 2
                                            }}>
                                                <Trans>Content</Trans>
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                color: "#c1c2c8",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "16px" : "26px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}>
                                                <Trans>vote003_content_1</Trans>
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                color: "#c1c2c8",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "16px" : "26px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}>
                                                <Trans>vote003_content_2</Trans>
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                color: "#c1c2c8",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "16px" : "26px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}>
                                                <Trans>vote003_content_3</Trans>
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                color: "#c1c2c8",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "16px" : "26px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}>
                                                <Trans>vote003_content_4</Trans>
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                color: "#c1c2c8",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "18px" : "26px",
                                                fontStyle: "normal",
                                                fontWeight: "700",
                                                mt: 2
                                            }}>
                                                <Trans>Objective</Trans>
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                color: "#c1c2c8",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "16px" : "26px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}>
                                                <Trans>vote003_objective</Trans>
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Typography>No current Dao Vote</Typography>
                                    )
                                }
                            </Box>
                        </Box>
                    </Paper>
                </Zoom>


            </PullToRefresh>
        </QueryClientProvider>
    );
};

export default DaoVote;