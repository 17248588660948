import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import ierc20ABIJson from "../abi/IERC20.json";
import OlympusStakingABIJson from "../abi/OlympusStakingv2.json";
import StakingHelperABIJson from "../abi/StakingHelper.json";
import DaoVoteABIJson from "../abi/OriginDaoVote.json";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances } from "./AccountSlice";
import { error, info } from "../slices/MessagesSlice";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError, IBaseAddressAsyncThunk } from "./interfaces";
import { segmentUA } from "../helpers/userAnalyticHelpers";
import { IERC20, OlympusStakingv2, StakingHelper, OriginDaoVote } from "src/typechain";
import { t } from "@lingui/macro";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { Web3Provider } from "@ethersproject/providers";

const ierc20ABI = ierc20ABIJson.abi;
const DaoVoteABI = DaoVoteABIJson.abi;

interface IVoteSession {
    loading?: boolean;
    id: number;
    proposal: string;
    startTime: number;
    period: number;
    supportVotes: number;
    againstVotes: number;
    allowance: number;
    userVotes: number;
    withdrawn?: boolean;
}

export const getVoteSession = createAsyncThunk(
    "daovote/getVoteSession",
    async ({ token, provider, address, networkID }: any, { dispatch }) => {
        if (!provider || !address) {
            dispatch(error(t`Please connect your wallet!`));
            return;
        }

        const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
        const DaoVoteContract = new ethers.Contract(addresses[networkID].DAO_VOTE_ADDRESS as string, DaoVoteABI, signer) as OriginDaoVote;
        let currentVoteId: BigNumber;
        let vote: IVoteSession;
        try {

            // won't run if stakeAllowance > 0
            const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
            const allowance = await ohmContract.allowance(address, addresses[networkID].DAO_VOTE_ADDRESS);
            console.log("allowance", allowance);
            currentVoteId = await DaoVoteContract.currentVoteId();
            const [proposal, startTime, endTime, supportVotes, againstVotes] = await DaoVoteContract.getVote(currentVoteId);
            const userVotes = await DaoVoteContract.getUserVotes(address);
            console.log("userVotes", userVotes);
            const withdrawnVotes = await DaoVoteContract.withdrawnVotes(address,currentVoteId);
            vote = {
                id: currentVoteId.toNumber(),
                proposal: proposal,
                startTime: startTime.toNumber(),
                period: endTime.toNumber(),
                supportVotes: Number(formatUnits(supportVotes, 9)),
                againstVotes: Number(formatUnits(againstVotes, 9)),
                allowance: Number(formatUnits(allowance, 9)),
                userVotes: Number(formatUnits(userVotes[0], 9)),
                withdrawn:withdrawnVotes
            }
            console.log("vote:", userVotes,withdrawnVotes);


        } catch (e: unknown) {
            console.error(e);
            // dispatch(error((e as IJsonRPCError).message));
            if ((e as any).code == "ACTION_REJECTED") {
                dispatch(error(t`User denied transaction signature.`));
                // dispatch(error((e as any).message));
            } else if (e == "cancel") {
                dispatch(error(t`User denied transaction signature.`));
            } else {
                // dispatch(error((e as any).message));
                dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
            }
            return;
        }

        return {
            id: currentVoteId.toNumber(),
            proposal: vote.proposal,
            startTime: vote.startTime,
            period: vote.period,
            supportVotes: vote.supportVotes,
            againstVotes: vote.againstVotes,
            allowance: vote.allowance,
            userVotes: vote.userVotes,
            withdrawn:vote.withdrawn
        }

    },
);

export const voteApprove = createAsyncThunk(
    "daovote/voteApprove",
    async ({ provider, address, networkID }: IBaseAddressAsyncThunk, { dispatch }) => {
        if (!provider) {
            dispatch(error(t`Please connect your wallet!`));
            return;
        }

        // const signer = provider.getSigner();
        const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
        const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;

        let approveTx;

        try {

            const estimateGas = await ohmContract.estimateGas.approve(addresses[networkID].DAO_VOTE_ADDRESS, ethers.utils.parseUnits("10000000", 18));
            approveTx = await ohmContract.approve(addresses[networkID].DAO_VOTE_ADDRESS, ethers.utils.parseUnits("10000000", 9), {
                gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
            });

            const pendingTxnType = "vote_approve";
            dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text: "approve", type: pendingTxnType }));
            await approveTx.wait();
        } catch (e: unknown) {
            console.error(e);
            const rpcError = e as IJsonRPCError;
            if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
                dispatch(
                    error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
                );
            } else if ((e as any).code == "ACTION_REJECTED") {
                dispatch(error(t`User denied transaction signature.`));
                // dispatch(error((e as any).message));
            } else if (e == "cancel") {
                dispatch(error(t`User denied transaction signature.`));
            } else {
                // dispatch(error((e as any).message));
                dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
            }
            return;
        } finally {
            if (approveTx) {
                // segmentUA(uaData);
                dispatch(clearPendingTxn(approveTx.hash));
            }
        }
        dispatch(getBalances({ address, networkID, provider }));
    },
);

export const transactVote = createAsyncThunk(
    "daovote/transactVote",
    async ({ provider, address, networkID, vote, amount }: any, { dispatch }) => {
        if (!provider) {
            dispatch(error(t`Please connect your wallet!`));
            return;
        }
        let voteTx;
        try {
            const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;

            const DaoVoteContract = new ethers.Contract(addresses[networkID].DAO_VOTE_ADDRESS as string, DaoVoteABI, signer) as OriginDaoVote;
            voteTx = await DaoVoteContract.vote(parseUnits(amount, 9), vote);
            console.log("voteTx", voteTx);
            const pendingTxnType = "vote";
            dispatch(fetchPendingTxns({ txnHash: voteTx.hash, text: "vote", type: pendingTxnType }));
            await voteTx.wait();
        }
        catch(err){
            console.error(err);
            dispatch(error((err as any).message));
        }
        finally{
            if(voteTx){
                dispatch(clearPendingTxn(voteTx.hash));
            }
        }
        
    },
);

export const voteWithdraw = createAsyncThunk(
    "daovote/voteWithdraw",
    async ({ provider, address, networkID, voteId }: any, { dispatch }) => {
        let voteTx;
        try {
            const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;

            const DaoVoteContract = new ethers.Contract(addresses[networkID].DAO_VOTE_ADDRESS as string, DaoVoteABI, signer) as OriginDaoVote;
            voteTx = await DaoVoteContract.withdrawTokens(voteId);
            console.log("voteTx", voteTx);
            const pendingTxnType = "vote_withdraw";
            dispatch(fetchPendingTxns({ txnHash: voteTx.hash, text: "vote_withdraw", type: pendingTxnType }));
            await voteTx.wait();
        }
        catch(err){
            console.error(err);
            dispatch(error((err as any).message));
        }
        finally{
            if(voteTx){
                dispatch(clearPendingTxn(voteTx?.hash));
            }
        }
    },
);


const initialState: IVoteSession = {
    loading: false,
    id: 0,
    proposal: "",
    startTime: 0,
    period: 0,
    supportVotes: 0,
    againstVotes: 0,
    allowance: 0,
    userVotes: 0,
    withdrawn: false
};

const daoSlice = createSlice({
    name: "dao",
    initialState,
    reducers: {
        setVoteSession(state, action) {
            state = action.payload;
        },
    },

    extraReducers: builder => {
        builder
            .addCase(getVoteSession.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    state.id = action.payload.id;
                    state.proposal = action.payload.proposal;
                    state.startTime = action.payload.startTime;
                    state.period = action.payload.period;
                    state.supportVotes = action.payload.supportVotes;
                    state.againstVotes = action.payload.againstVotes;
                    state.allowance = action.payload.allowance;
                    state.userVotes = action.payload.userVotes;
                    state.withdrawn = action.payload.withdrawn;
                }
            })
            .addCase(getVoteSession.rejected, (state, { error }) => {
                state.loading = false;
                console.error(error.message);
            })
            .addCase(getVoteSession.pending, state => {
                state.loading = true;
            })
            .addCase(voteApprove.pending, state => {
                state.loading = true;
            })
            .addCase(voteApprove.fulfilled, state => {
                state.loading = false;
            })
            .addCase(voteApprove.rejected, state => {
                state.loading = false;
            })
            .addCase(transactVote.pending, state => {
                state.loading = true;
            })
            .addCase(transactVote.fulfilled, state => {
                state.loading = false;
            })
            .addCase(transactVote.rejected, state => {
                state.loading = false;
            })
    }
});

export const { setVoteSession } = daoSlice.actions;
export default daoSlice.reducer;    