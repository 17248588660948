import { Paper, Box, Typography, Link, Button } from "@mui/material";
import { useMobile } from 'src/hooks';
import { Trans, t } from "@lingui/macro";
import { Skeleton } from "@mui/material";
import { useAppSelector } from "src/hooks";
import { NavLink } from "react-router-dom";


export const LongStakeCard = ({ stake }: any) => {
    const isSmallScreen = useMobile();
    const isLoding = useAppSelector((state: any) => state.longStake.isLoading);
    return (
        <Paper
            id={`${stake.name}--stake`}
            className="stake-data-card ohm-card"
            sx={{
                backgroundColor: "transparent",
                boxShadow: "none",
                mb: "30px !important",
            }}
        >

            <Box
                sx={{
                    mb: isSmallScreen ? "20px !important" : "15px !important",
                }}
                className="bond-pair"
            >
                <div className="bond-name">
                    <Typography
                        sx={{
                            color: "#C7C8CC",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        }}
                    >
                        {stake.displayName}
                    </Typography>

                </div>
            </Box>
            <div className="data-row">
                <Typography
                    sx={{
                        color: "#868b8f",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Vesting Period</Trans>
                </Typography>

                {isLoding ? <Skeleton width="50px" /> : <Typography
                    className="bond-price"
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                > {stake.vestingPeriod}</Typography>}

            </div>
            <div className="data-row">
                <Typography
                    sx={{
                        color: "#868b8f",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Extra Rate</Trans>
                </Typography>

                {isLoding ? <Skeleton width="50px" /> : <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >{stake.extraRate}%</Typography>}

            </div>

            <Box
                sx={{
                    mb: isSmallScreen ? "20px !important" : 1.375,
                }}
                className="data-row"
            >
                <Typography
                    sx={{
                        color: "#868b8f",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Staked</Trans>
                </Typography>

                {isLoding ? (
                    <Skeleton width="80px" />
                ) : (
                    <Typography
                        sx={{
                            color: "#c7c8cc",
                            fontFamily: "AGaramond LT",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                        }}
                    > {Number(stake.staked).toFixed(1)}</Typography>
                )}

            </Box>
            <Link component={NavLink} to={`/long-term-stake/${stake.name}`} style={(stake.name == 'btc' || stake.name == 'eth') ? { pointerEvents: 'none' } : {}}>
                <Button
                    sx={{
                        borderRadius: "30px",
                        border: stake.name === "lgns_dai_lp" ? "1.3px solid rgb(139, 97, 246)" : "1.3px solid #B50C05",
                        background:
                            stake.name === "lgns_dai_lp" ? "rgb(139, 97, 246)" : "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                    }}
                    variant="contained"
                    fullWidth
                    disabled={!stake.status}
                >
                    <Typography
                        sx={{
                            color: "#FFF",
                            fontFamily: "AGaramond LT",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        }}
                    >
                        {!stake.status ? t`Maintenance` : t`Stake ${stake.displayName}`}
                    </Typography>
                </Button>
            </Link>
        </Paper>
    )
}