import { PullToRefresh } from "src/components/PullToRefresh";
import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, Modal, OutlinedInput, Paper, Typography, Zoom } from "@mui/material";
import { Trans, t } from "@lingui/macro";
import { useMobile, useWeb3Context } from "src/hooks";
import { useEffect, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useAppSelector } from "src/hooks";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { approve, getLongStakes, getLongStakeStatus } from "src/slices/LongStakeSlice";
import { trim } from "src/helpers";
import { prettyVestingPeriod } from "src/helpers";
import { RootState } from "src/store";
import { formatUnits } from "@ethersproject/units";
import { LongStakeCard } from "./LongStakeRow";

export const AmountDialog = ({open,onClose,onRedeem,onMax, isLoading}:any)=>{
    const [quantity, setQuantity] = useState('0');
    const handleOnChangeInterest = (e:any)=>{
        setQuantity(e.target.value);
    }
    const isSmallScreen = useMobile();
    return(
        <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Paper sx={{ width: '300px', height: '300px', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <Box sx={{ width: '300px', height: '300px', backgroundColor: 'rgba(0,0,0)', p: 2, borderRadius: '12px' }}>
                        <Box>
                            <Typography sx={{ color: '#c7c8cc', fontFamily: 'AGaramond LT', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal' }}>
                                <Trans>Redeem Interest</Trans>
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 4 }}>
                            <FormControl
                                // className="ohm-input"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                sx={{
                                    height: "40px",
                                    m: "0px !important",
                                    mr: "15px !important",
                                    mb: isSmallScreen ? "20px !important" : 0,
                                }}
                            >
                                <InputLabel
                                    htmlFor="outlined-adornment-amount"
                                    sx={{
                                        top: "-7px",
                                        color: "#C7C8CC",
                                        fontFamily: "SF Pro Display",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "normal",
                                    }}
                                >
                                    <Trans>Amount</Trans>
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    sx={{
                                        width: "100%",
                                        height: "42px",
                                        borderRadius: "60px",
                                        border: "1px solid rgba(138, 147, 160, 0.50)",
                                        background: "#3C3F48",
                                        color: "#c7c8cc",
                                        // mb: 1,
                                        boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                                        '& input[type="number"]': {
                                            py: 0,
                                        },
                                    }}
                                    value={quantity}
                                    onChange={handleOnChangeInterest}
                                    // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    // labelWidth={55}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Button
                                                variant="text"
                                                onClick={()=>onMax(setQuantity)}
                                                sx={{
                                                    color: "#B50C05",
                                                    fontFamily: "Euclid Square",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                <Trans>Max</Trans>
                                            </Button>

                                        </InputAdornment>
                                    }
                                    slotProps={{ root: { 'aria-hidden': undefined } }}
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <LoadingButton
                                className="stake-button main-btn"
                                variant="contained"
                                disabled={isLoading}
                                loading={isLoading}
                                loadingPosition="end"
                                // endIcon={<CircularProgress size={20}/>}
                                endIcon={<></>}
                                onClick={()=>onRedeem(quantity)}
                                sx={{
                                    // width: "100%",
                                    borderRadius: "30px",
                                    border: "1.3px solid #B50C05",
                                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                    boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                    color: "#FFFFFF",
                                    fontFamily: "AGaramond LT",
                                    fontSize: "18px",
                                    fontWeight: "700",
                                    mb: isSmallScreen ? "20px !important" : 0,
                                    overflow: "hidden",
                                    "&:hover": {
                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                    },
                                }}
                            >
                                {isLoading? t`claiming`: t`Claim`}
                            </LoadingButton>
                        </Box>
                    </Box>
                </Paper>

            </Modal>
    )
}