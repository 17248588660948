import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses } from "../constants";
import ierc20AbiJson from "../abi/IERC20.json";
import sOHMv2Json from "../abi/sOhmv2.json";
import OlympusStakingABIJson from "../abi/OlympusStakingv2.json";
import RewardDistributorAbiJson from "../abi/RewardDistributor.json";
import TurboLotteryAbiJson from "../abi/TurboLottery.json";
import THAExchangeAbiJson from "../abi/THAExchange.json";
import LotteryAbiJson from "../abi/Lottery.json";

import { getTokenDecimals, getTokenDecimals2, setAll } from "../helpers";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk, ICalcUserBondDetailsAsyncThunk } from "./interfaces";
import { FuseProxy, IERC20, SOhmv2, WsOHM, OlympusStakingv2 } from "src/typechain";
import { Web3Provider } from "@ethersproject/providers";
import { providers } from '@0xsequence/multicall';
import { formatUnits } from "@ethersproject/units";

const ierc20Abi = ierc20AbiJson.abi;
const sOHMv2 = sOHMv2Json.abi;
const OlympusStakingABI = OlympusStakingABIJson.abi;
const RewardDistributorAbi = RewardDistributorAbiJson.abi;
const TurboLotteryAbi = TurboLotteryAbiJson.abi;
const LotteryAbi = LotteryAbiJson.abi;
const THAExchangeAbi = THAExchangeAbiJson.abi;

interface IUserBalances {
    balances: {
        ohm: string;
        sOHM: string;
        fsohm: string;
        wsohm: string;
        wsohmAsSohm: string;
        pool: string;
        // busd: string;
    };
}

export const getBalances = createAsyncThunk(
    "account/getBalances",
    async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
        // const signer =await provider?.getSigner?provider.getSigner():provider;
        const multiProvider = new providers.MulticallProvider(provider);

        try {
            const ohmContract = new ethers.Contract(
                addresses[networkID].OHM_ADDRESS as string,
                ierc20Abi,
                multiProvider,
            ) as IERC20;
            const stakingContract = new ethers.Contract(
                addresses[networkID].STAKING_ADDRESS as string,
                OlympusStakingABI,
                multiProvider,
            ) as OlympusStakingv2;
            const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, multiProvider) as SOhmv2;
            const USDTContract = new ethers.Contract(addresses[networkID].USDT_REAL_ADDRESS as string, sOHMv2, multiProvider) as SOhmv2;
            const daiContract = new ethers.Contract(addresses[networkID].DAI_ADDRESS as string, sOHMv2, multiProvider) as SOhmv2;

            // const sohmBalance = await sohmContract.balanceOf(address);
            // const daiBalance = await daiContract.balanceOf(address);
            // const usdtBalance = await USDTContract.balanceOf(address);
            // const ohmBalance = await ohmContract.balanceOf(address);
            // const daiAllowanceInTurbine = await daiContract.allowance(address, addresses[networkID].TURBINE_ADDRESS);
            // const usdtAllowanceInTurbine = await USDTContract.allowance(address, addresses[networkID].TURBINE_ADDRESS);
            // const ohmAllowanceInMintTUSDB = await ohmContract.allowance(address, addresses[networkID].TUSDB_ADDRESS);
            // const info = await stakingContract.warmupInfo(address);
            // const epoch = await stakingContract.epoch();

            const calls = [
                sohmContract.balanceOf(address),
                daiContract.balanceOf(address),
                USDTContract.balanceOf(address),
                ohmContract.balanceOf(address),
                daiContract.allowance(address, addresses[networkID].TURBINE_ADDRESS),
                USDTContract.allowance(address, addresses[networkID].TURBINE_ADDRESS),
                ohmContract.allowance(address, addresses[networkID].TUSDB_ADDRESS),
                stakingContract.warmupInfo(address),
                stakingContract.epoch()
            ];

            const [
                sohmBalance,
                daiBalance,
                usdtBalance,
                ohmBalance,
                daiAllowanceInTurbine,
                usdtAllowanceInTurbine,
                ohmAllowanceInMintTUSDB,
                info,
                epoch
            ] = await Promise.all(calls);


            let gonsBal, isLocked;
            gonsBal = await sohmContract.balanceForGons((info as any).gons);

            isLocked = Number((epoch as any).number) < Number((info as any).expiry);

            // const thaExchangeRate = await thaExchangeContract.exchangeRate();

            const [daiDecimals, usdtDecimals] = await Promise.all([
                getTokenDecimals(addresses[networkID].DAI_ADDRESS as string, networkID),
                getTokenDecimals(addresses[networkID].USDT_REAL_ADDRESS as string, networkID),
            ]);
            // console.log("deicmals", busdDecimals, usdtDecimals, thaDecimals);
            return {
                balances: {
                    ohm: ethers.utils.formatUnits(ohmBalance as any, "gwei"),
                    sOHM: ethers.utils.formatUnits(sohmBalance as any, "gwei"),
                    gonsBal: gonsBal && ethers.utils.formatUnits(gonsBal, "gwei"),
                    dai: ethers.utils.formatUnits(daiBalance as any, daiDecimals),
                    usdt: ethers.utils.formatUnits(usdtBalance as any, usdtDecimals),
                    // bnb: ethers.utils.formatUnits(bnbBalance, "18"),
                    // tha: ethers.utils.formatUnits(thaBalance, thaDecimals),
                },
                isLocked,
                // usdtAllowanceInTHAExchange: ethers.utils.formatUnits(usdtAllowanceInTHAExchange, usdtDecimals),
                daiAllowanceInTurbine: ethers.utils.formatUnits(daiAllowanceInTurbine as any, daiDecimals),
                usdtAllowanceInTurbine: ethers.utils.formatUnits(usdtAllowanceInTurbine as any, usdtDecimals),
                ohmAllowanceInMintTUSDB: ethers.utils.formatUnits(ohmAllowanceInMintTUSDB as any, "9"),
                // thaExchangeRate,
            };
        } catch (error) {
            console.error("error", error);
        }
    },
);

export const loadAccountDetails = createAsyncThunk(
    "account/loadAccountDetails",
    async ({ networkID, provider, address }: IBaseAddressAsyncThunk, { dispatch }) => {
        const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
        // console.log('[debug]account:', signer, addresses[networkID]);

        try {
            //   console.log("[debug]loading account details: ", networkID, address, addresses[networkID].OHM_ADDRESS);
            const ohmContract = new ethers.Contract(
                addresses[networkID].OHM_ADDRESS as string,
                ierc20Abi,
                signer,
            ) as IERC20;
            const stakeAllowance = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
            //   console.log("loading 2", networkID, stakeAllowance);

            const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, signer) as SOhmv2;
            const daiContract = new ethers.Contract(
                addresses[networkID].USDT_ADDRESS as string,
                ierc20Abi,
                signer,
            ) as IERC20;
            const usdtContract = new ethers.Contract(
                addresses[networkID].USDT_REAL_ADDRESS as string,
                ierc20Abi,
                signer,
            ) as IERC20;
            const lotteryContract = new ethers.Contract(addresses[networkID].LOTTERY_ADDRESS as string, LotteryAbi, signer);

            //   console.log("loading 1:", address, networkID);

            const unstakeAllowance = await sohmContract.allowance(address, addresses[networkID].STAKING_ADDRESS);

            const communityRewardContract = new ethers.Contract(
                addresses[networkID].COMMUNITY_REWARD_ADDRESS as string,
                RewardDistributorAbi,
                signer,
            );

            const mintRewardContract = new ethers.Contract(
                addresses[networkID].MINT_REWARD_ADDRESS as string,
                RewardDistributorAbi,
                signer,
            );
            // console.log("rewardList");
            //   console.log('[debug]account:', address);
            const rewardList = await Promise.all([
                communityRewardContract.claimable(address),
                // stakingRewardContract.claimable(address),
                // additionStakingRewardContract.claimable(address),
                mintRewardContract.claimable(address),
                // fomoLotteryContract.claimableRewards(address),
                // turboLotteryContract.claimableRewards(address),
            ]);
            // console.log("rewardList 1", rewardList);
            // } catch (error) {
            //   console.log("error", error);
            // }
            const ohmAllowanceInRouter = await ohmContract.allowance(address, addresses[networkID].pancakeRouter);
            const usdtAllowanceInRouter = await usdtContract.allowance(address, addresses[networkID].pancakeRouter);
            const daiAllowanceInRouter = await daiContract.allowance(address, addresses[networkID].pancakeRouter);

            //   console.log("loading 1 getBalances", ohmAllowanceInRouter, usdtAllowanceInRouter);
            await dispatch(getBalances({ address, networkID, provider }));
            // console.log("loading 2 getBalances", rewardList);
            const total = rewardList[0];
            const [usdtDecimals, daiDecimals] = await Promise.all([
                getTokenDecimals(addresses[networkID].USDT_REAL_ADDRESS as string, networkID),
                getTokenDecimals(addresses[networkID].DAI_ADDRESS as string, networkID),
            ]);
            return {
                staking: {
                    ohmStake: +stakeAllowance,
                    ohmUnstake: +unstakeAllowance,
                },
                reward: {
                    community: +ethers.utils.formatUnits(rewardList[0], "9"),
                    // staking: +ethers.utils.formatUnits(rewardList[1], "9"),
                    // additionStaking: +ethers.utils.formatUnits(rewardList[2], "9"),
                    total: +ethers.utils.formatUnits(total, "9"),
                    mint: +ethers.utils.formatUnits(rewardList[1], "9"),
                    // fomoLottery: +ethers.utils.formatUnits(rewardList[4], "18"),
                    // turboLottery: +ethers.utils.formatUnits(rewardList[5], "18"),
                },
                ohmAllowanceInRouter: ethers.utils.formatUnits(ohmAllowanceInRouter, "9"),
                usdtAllowanceInRouter: ethers.utils.formatUnits(usdtAllowanceInRouter, usdtDecimals),
                daiAllowanceInRouter: ethers.utils.formatUnits(daiAllowanceInRouter, daiDecimals),
            };
        } catch (error) {
            console.error("loading error", error);
        }
    },
);

export interface IUserBondDetails {
    allowance: number[];
    interestDue: number;
    bondMaturationBlock: number;
    pendingPayout: string; //Payout formatted in gwei.
}

export const calculateUserBondDetails = createAsyncThunk(
    "account/calculateUserBondDetails",
    async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {

        if (!address) {
            return {
                bond: "",
                displayName: "",
                bondIconSvg: "",
                isLP: false,
                allowance: [0, 0],
                balance: ["0", "0"],
                interestDue: 0,
                bondMaturationBlock: 0,
                pendingPayout: "",
            };
        }

        const multiProvider = new providers.MulticallProvider(provider);
        // const signer=await provider?.getSigner?provider.getSigner():provider;

        try {

            const sohmContract = new ethers.Contract(
                addresses[networkID].SOHM_ADDRESS as string,
                sOHMv2,
                provider
            )
            const bondContract = bond.getContractForBond(networkID, multiProvider as any);
            const reserveContract = bond.getContractForReserve(networkID, multiProvider as any);

            const daiContract = new ethers.Contract(
                addresses[networkID].DAI_ADDRESS as string,
                ierc20Abi,
                multiProvider//provider,
            ) as IERC20;

            const usdtContract = new ethers.Contract(
                addresses[networkID].USDT_REAL_ADDRESS as string,
                ierc20Abi,
                multiProvider//provider,
            ) as IERC20;

            let bondDetails = await bondContract.getBondInfoData(address);

            // reserveContract.address === "0xEa77804D131c0ad997333a5c795aFA54f2968F22" &&
              console.log("[debug]bonddebug bondasd bondDetails", bondDetails, bond.name, bondDetails.length);
            if (bondDetails.length > 0) {
                console.log('[debug]bond-v1: 0');
                if (bond.name.startsWith("lgns_dai_lp")) {
                    console.log('[debug]bond-v1: 1');
                    bondDetails = await Promise.all(
                        bondDetails.map(async (bondItem: any,idx:number) => {
                            console.log('[debug]bond-v1: 2');
                            let pendingPayout, bondMaturationBlock;


                            let principalAndInterest, principal;
                            let interestDue: BigNumberish;

                            if (bond.isStaked) {
                                principalAndInterest = await sohmContract.balanceForGons(bondItem.gons)
                                principal = formatUnits(bondItem.payout, 9);
                                console.log('[DEBUG]bondisstaked: principal:', principal);
                                interestDue = Number(formatUnits(principalAndInterest, 9))
                                console.log('[DEBUG]bondisstaked:interestDue:', interestDue)
                            }
                            else {
                                interestDue = Number(bondItem.payout.toString()) / Math.pow(10, 9);
                            }

                            // // interestDue = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
                            bondMaturationBlock = +bondItem.vesting + +bondItem.lastBlock;
                            pendingPayout = await bondContract.pendingPayoutFor(address, idx, false);

                            let allowance,
                                balance = BigNumber.from(0);
                            allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
                            balance = await reserveContract.balanceOf(address);

                            const daiBalance = await daiContract.balanceOf(address);
                            // console.log("bondhelper", bond.getAddressForBondHelper(networkID));
                            const daiAllowance = await daiContract.allowance(
                                address,
                                bond.getAddressForBondHelper(networkID) as string,
                            );
                            // formatEthers takes BigNumber => String
                            const balanceVal = ethers.utils.formatEther(balance);
                            // balanceVal should NOT be converted to a number. it loses decimal precision
                            console.log('[debug]bond-v1: 4');
                            const usdtBalance = await usdtContract.balanceOf(address);
                            const usdtAllowance = await usdtContract.allowance(
                                address,
                                bond.getAddressForBondHelper(networkID) as string,
                            );
                            const [usdtDecimals] = await Promise.all([
                                getTokenDecimals(addresses[networkID].USDT_REAL_ADDRESS as string, networkID),
                            ]);
                            console.log('[debug]bond-v1: 3');
                            return {
                                bond: bond.name,
                                displayName: bond.displayName,
                                bondIconSvg: bond.bondIconSvg,
                                isLP: bond.isLP,
                                isStaked: bond.isStaked,
                                principal,
                                allowance: [Number(daiAllowance.toString()), Number(usdtAllowance.toString())],
                                balance: [ethers.utils.formatEther(daiBalance), ethers.utils.formatUnits(usdtBalance, usdtDecimals)],
                                interestDue,
                                bondMaturationBlock,
                                pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
                                id: bondItem.id,
                                idx
                            };
                        }),
                    );
                } else {
                    console.log('[debug]bond-v1:1 - 0',bond);
                    bondDetails = await Promise.all(
                        bondDetails.map(async (bondItem: any,idx:number) => {

                            console.log('[debug]bond-v1: 1-1');
                            let bondMaturationBlock;

                            let principalAndInterest, principal;
                            let interestDue: BigNumberish;

                            if (bond.isStaked) {
                                console.log('[DEBUG]bondisstaked: ', bondItem);
                                principalAndInterest = await sohmContract.balanceForGons(bondItem.gons)
                                console.log('[DEBUG]bondisstaked:', principalAndInterest)
                                principal = formatUnits(bondItem.payout, 9);
                                console.log('[DEBUG]bondisstaked: principal:', principal);
                                interestDue = Number(formatUnits(principalAndInterest, 9))
                                console.log('[DEBUG]bondisstaked: interestDue:', interestDue);
                            }
                            else {

                                interestDue = Number(bondItem.payout.toString()) / Math.pow(10, 9);
                            }

                            console.log('[debug]bond-v1: 1-2');
                            bondMaturationBlock = +bondItem.vesting + +bondItem.lastBlock;


                            try {
                                const [pendingPayout, allowance, balance, decimals] = await Promise.all([
                                    bondContract.pendingPayoutFor(address, idx, false),
                                    reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string),
                                    reserveContract.balanceOf(address),
                                    getTokenDecimals2(reserveContract.address, multiProvider)
                                ])

                                console.log('[debug]bond-v1: 1-3');
                                // formatEthers takes BigNumber => String
                                const balanceVal = ethers.utils.formatUnits(balance, decimals);
                                // balanceVal should NOT be converted to a number. it loses decimal precision
                                return {
                                    bond: bond.name,
                                    displayName: bond.displayName,
                                    bondIconSvg: bond.bondIconSvg,
                                    isLP: bond.isLP,
                                    isStaked: bond.isStaked,
                                    principal,
                                    allowance: [Number(allowance.toString()), Number(allowance.toString())],
                                    balance: [balanceVal, balanceVal],
                                    interestDue,
                                    bondMaturationBlock,
                                    pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
                                    id: bondItem.id,
                                    idx
                                };
                            }
                            catch (err) {
                                console.error('[error]bonderror:', err);
                            }
                        }),
                    );
                }
                // console.log(" bondasd bondDetails", bondDetails);
                return bondDetails;
            }
            else {
                // console.log('[debug]bond ...........');

                console.log('[debug]bond-v1: 2 ------ 0');
                if (bondDetails.length == 0) {
                    if (bond.name.startsWith("lgns_dai_lp")) {
                        console.log('[debug]bond-v1: 2 ------ 1');
                        let allowance, balance = BigNumber.from(0);
                        allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
                        
                        console.log('[debug]bond-v1: 2 ------ 2');


                        balance = await reserveContract.balanceOf(address);
                        const daiBalance = await daiContract.balanceOf(address);
                        // console.log("bondasd bondDetails daiBalance", daiBalance, bond.getAddressForBondHelper(networkID));
                        const daiAllowance = await daiContract.allowance(
                            address,
                            bond.getAddressForBondHelper(networkID) as string,
                        );
                        const usdtBalance = await usdtContract.balanceOf(address);
                        // // // console.log("bondhelper", bond.getAddressForBondHelper(networkID));
                        const usdtAllowance = await usdtContract.allowance(
                            address,
                            bond.getAddressForBondHelper(networkID) as string,
                        );

                        const balanceVal = ethers.utils.formatEther(balance);

                        // formatEthers takes BigNumber => String

                        // balanceVal should NOT be converted to a number. it loses decimal precision

                        // const usdcBalance = await usdcContract.balanceOf(address);
                        // const usdcAllowance = await usdcContract.allowance(
                        //   address,
                        //   bond.getAddressForBondHelper(networkID) as string,
                        // );
                        const [usdtDecimals] = await Promise.all([
                            getTokenDecimals(addresses[networkID].USDT_REAL_ADDRESS as string, networkID),
                        ]);
                        return [
                            {
                                bond: bond.name,
                                displayName: bond.displayName,
                                bondIconSvg: bond.bondIconSvg,
                                isLP: bond.isLP,
                                isStaked: bond.isStaked,
                                allowance: [
                                    Number(daiAllowance.toString()),
                                    // Number(allowance.toString()),
                                    Number(usdtAllowance.toString()),
                                    // Number(usdcAllowance.toString()),
                                ],
                                balance: [
                                    ethers.utils.formatEther(daiBalance),
                                    // balanceVal,
                                    ethers.utils.formatUnits(usdtBalance, usdtDecimals),
                                    // ethers.utils.formatUnits(usdcBalance, usdcDecimals),
                                ],
                                interestDue: 0,
                                bondMaturationBlock: 0,
                                pendingPayout: "",
                            },
                        ];
                    } else {
                        console.log('[debug]bond-v1: 3 ------ 0');
                        let allowance,
                            balance = BigNumber.from(0);
                        allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);

                        console.log('[debug]bond-v1: 3 ------ 1');
                        balance = await reserveContract.balanceOf(address);
                        const decimals = await getTokenDecimals(reserveContract.address, networkID);
                        // console.log("bondasd balance", bond.name, balance, "decimals", decimals);
                        // formatEthers takes BigNumber => String
                        console.log('[debug]bond-v1: 3 ------ 2');
                        const balanceVal = ethers.utils.formatUnits(balance, decimals);
                        allowance = ethers.utils.formatUnits(allowance, decimals);
                        return [
                            {
                                bond: bond.name,
                                displayName: bond.displayName,
                                bondIconSvg: bond.bondIconSvg,
                                isLP: bond.isLP,
                                isStaked: bond.isStaked,
                                allowance: [Number(allowance.toString()), Number(allowance.toString())],
                                balance: [balanceVal, balanceVal],
                                interestDue: 0,
                                bondMaturationBlock: 0,
                                pendingPayout: "",
                            },
                        ];
                    }
                }
                else {
                    let pendingPayout, bondMaturationBlock;

                    let interestDue: BigNumberish = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
                    // interestDue = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
                    bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
                    pendingPayout = await bondContract.pendingPayoutFor(address);
                    // } catch (error) {
                    //   console.log("err", error);
                    // }
                    // console.log("bondDetails", bondDetails);

                    let allowance,
                        balance = BigNumber.from(0);
                    allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
                    balance = await reserveContract.balanceOf(address);
                    const usdtBalance = await usdtContract.balanceOf(address);
                    const usdtAllowance = await usdtContract.allowance(
                        address,
                        bond.getAddressForBondHelper(networkID) as string,
                    );
                    // formatEthers takes BigNumber => String
                    const balanceVal = ethers.utils.formatEther(balance);
                    // balanceVal should NOT be converted to a number. it loses decimal precision
                    //   console.log('[debug]bond:', bond.name);
                    return [
                        {
                            bond: bond.name,
                            displayName: bond.displayName,
                            bondIconSvg: bond.bondIconSvg,
                            isLP: bond.isLP,
                            isStaked: bond.isStaked,
                            allowance: [Number(usdtAllowance.toString()), Number(allowance.toString())],
                            balance: [ethers.utils.formatEther(usdtBalance), balanceVal],
                            interestDue,
                            bondMaturationBlock,
                            pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
                        },
                    ];
                }
            }
        } catch (error) {
            console.error("[error]bond bondContract error", error);
        }
    },
);

export const calculateUserDirectBondDetails = createAsyncThunk(
    "account/calculateUserDirectBondDetails",
    async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
        if (!address) {
            return {
                bond: "",
                displayName: "",
                bondIconSvg: "",
                isLP: false,
                allowance: [0, 0],
                balance: ["0", "0"],
                interestDue: 0,
                bondMaturationBlock: 0,
                pendingPayout: "",
            };
        }
        // dispatch(fetchBondInProgress());
        // const signer = provider.getSigner();
        // Calculate bond details.
        // console.log('[debug]bond ...........');
        // console.log("bond", bond);

        const signer = await provider instanceof Web3Provider ? provider.getSigner() : provider;
        try {
            const bondContract = bond.getContractForBond(networkID, signer);
            const reserveContract = bond.getContractForReserve(networkID, signer);
            const busdContract = new ethers.Contract(
                addresses[networkID].USDT_ADDRESS as string,
                ierc20Abi,
                signer,
            ) as IERC20;

            // let bondDetails, interestDue;
            // try {
            let bondDetails = await bondContract.inviteBond(address);

            let pendingPayout, bondMaturationBlock;

            let interestDue: BigNumberish = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
            // interestDue = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
            bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
            pendingPayout = await bondContract.pendingPayoutFor(address, "0", true);
            // } catch (error) {
            //   console.log("err", error);
            // }
            // console.log("bondDetails direct", bondDetails);
            let allowance,
                balance = BigNumber.from(0);
            allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
            balance = await reserveContract.balanceOf(address);
            const busdBalance = await busdContract.balanceOf(address);
            const busdAllowance = await busdContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
            // formatEthers takes BigNumber => String
            const balanceVal = ethers.utils.formatEther(balance);
            // balanceVal should NOT be converted to a number. it loses decimal precision
            return [
                {
                    bond: bond.name,
                    displayName: bond.displayName,
                    bondIconSvg: bond.bondIconSvg,
                    isLP: bond.isLP,
                    allowance: [Number(busdAllowance.toString()), Number(allowance.toString())],
                    balance: [ethers.utils.formatEther(busdBalance), balanceVal],
                    interestDue,
                    bondMaturationBlock,
                    pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
                },
            ];
        } catch (error) {
            console.error(error, "calculateUserDirectBondDetails error");
        }
    },
);

interface IAccountSlice {
    bonds: { [key: string]: IUserBondDetails };
    stakeLPBonds: { [key: string]: IUserBondDetails };
    stakeStableBonds: { [key: string]: IUserBondDetails };
    directBonds: { [key: string]: IUserBondDetails };
    balances: {
        ohm: string;
        sOHM: string;
        dai: string;
        oldsohm: string;
        fsohm: string;
        wsohm: string;
        wsohmAsSohm: string;
        pool: string;
        busd: string;
        gonsBal: string;
        usdt: string;
        usdc: string;
        wbnb: string;
        bnb: string;
        // xph: string;
        // sxph: string;
        xphV2: string;
    };
    loading: boolean;
    staking: {
        ohmStake: number | null;
        ohmUnstake: number | null;
    };
    reward: {
        community: number;
        staking: number;
        additionStaking: number;
        total: number;
        mint: number;
        fomoLottery: number;
        turboLottery: number;
    };
    pooling: {
        sohmPool: number;
    };
    isLocked: boolean;
    ohmAllowanceInRouter: string;
    busdAllowanceInRouter: string;
    usdtAllowanceInRouter: string;
    daiAllowanceInRouter: string;
    usdtAllowanceInTurbine?: string;
    daiAllowanceInTurbine?: string;
    ohmAllowanceInMintTUSDB?: string;
    thaExchangeRate?: string;
    usdcAllowanceInRouter: string;
    wbnbAllowanceInRouter: string;
    ohmAllowanceInMigration: string;
    sohmAllowanceInMigration: string;
    usdtAllowanceInTHAExchange: string;
}

const initialState: IAccountSlice = {
    loading: false,
    bonds: {},
    stakeLPBonds: {},
    stakeStableBonds: {},
    directBonds: {},
    balances: {
        ohm: "",
        sOHM: "",
        dai: "",
        oldsohm: "",
        fsohm: "",
        wsohm: "",
        pool: "",
        wsohmAsSohm: "",
        busd: "",
        usdt: "",
        usdc: "",
        wbnb: "",
        bnb: "",
        gonsBal: "",
        // xph: "",
        // sxph: "",
        xphV2: "",
    },
    staking: { ohmStake: null, ohmUnstake: null },
    reward: { community: 0, staking: 0, additionStaking: 0, mint: 0, turboLottery: 0, fomoLottery: 0, total: 0 },
    // wrapping: { sohmWrap: 0, wsohmUnwrap: 0 },
    pooling: { sohmPool: 0 },
    isLocked: false,
    ohmAllowanceInRouter: "",
    busdAllowanceInRouter: "",
    usdtAllowanceInRouter: "",
    daiAllowanceInRouter: "",
    usdcAllowanceInRouter: "",
    wbnbAllowanceInRouter: "",
    ohmAllowanceInMigration: "",
    sohmAllowanceInMigration: "",
    usdtAllowanceInTurbine: "",
    daiAllowanceInTurbine: "",
    usdtAllowanceInTHAExchange: "",
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        fetchAccountSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAccountDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAccountDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAccountDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(getBalances.pending, state => {
                state.loading = true;
            })
            .addCase(getBalances.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getBalances.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(calculateUserBondDetails.pending, state => {
                state.loading = true;
            })
            .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
                if (!action.payload) return;
                let bond;
                bond = action.payload[0] && action.payload[0].bond;
                console.log('[debug]bonddebug allowance:', action.payload)
                if (bond && action.payload[0].isStaked) {

                    console.log('[debug]bonddebug allowance 1:', bond,action.payload[0].isLP)
                    if (action.payload[0].isLP) {

                        state.stakeLPBonds[bond] = action.payload;
                    } else {
                        console.log('[debug]bonddebug allowance 2:', bond,action.payload);
                        state.stakeStableBonds[bond] = action.payload;
                    }
                } else {
                    state.bonds[bond] = action.payload;
                }
                // state.bonds[bond] = action.payload;
                state.loading = false;
            })
            .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(calculateUserDirectBondDetails.pending, state => {
                state.loading = true;
            })
            .addCase(calculateUserDirectBondDetails.fulfilled, (state: any, action: any) => {
                if (!action.payload) return;
                let bond;
                bond = action.payload[0].bond;
                state.directBonds[bond] = action.payload;
                state.loading = false;
            })
            .addCase(calculateUserDirectBondDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            });
    },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
