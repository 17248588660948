import { PullToRefresh } from "src/components/PullToRefresh";
import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, Modal, OutlinedInput, Paper, Typography, Zoom } from "@mui/material";
import { Trans, t } from "@lingui/macro";
import { useMobile, useWeb3Context } from "src/hooks";
import { useEffect, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useAppSelector } from "src/hooks";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { approve, getLongStakes, getLongStakeStatus } from "src/slices/LongStakeSlice";
import { trim } from "src/helpers";
import { prettyVestingPeriod } from "src/helpers";
import { RootState } from "src/store";
import { formatUnits } from "@ethersproject/units";
import { LongStakeCard } from "./LongStakeRow";
import { AmountDialog } from "./AmountDialog";
import { redeemInterest, redeemExtraInterest, redeemPrincipal } from "src/slices/LongStakeSlice";

const CountDownButton = ({ period,title,onTimeOut }: any) => {
    const [seconds, setSeconds] = useState(period);

    useEffect(()=>{
        const interval = setInterval(()=>{
            setSeconds(seconds-1);
            if(seconds<=0){
                onTimeOut();
            }
        },1000);
        return ()=>clearInterval(interval);
    },[seconds,period]);

    function formatTime(seconds:number) {
        console.log('seconds:',seconds);
        const hrs = Math.floor(seconds / 3600).toString().padStart(2, '0');
        const mins = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
        const secs = (seconds % 60).toString().padStart(2, '0');
        return `${hrs}:${mins}:${secs}`;
    }
    return (
        <Box sx={{
            mt: 1, display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems: 'center',
            width: '100%', height: '32px', borderRadius: '30px', border: '1.3px solid #B50C05',
            boxShadow: '5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059'
        }}>
            <Typography sx={{
                color: '#FFF', fontFamily: 'AGaramond LT', fontSize: '12px',
                fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
            }}>{title}</Typography>
            <Typography sx={{
                color: '#FFF', fontFamily: 'AGaramond LT', fontSize: '10px',
                fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
            }}>{formatTime(seconds)}</Typography>
        </Box>
    )
}


const LongStakeCardData = ({ stake, stakeIndex }: { stake: any, stakeIndex: number }) => {
    const { isSmallScreen } = useMobile();
    const [showRedeemInterestDialog, setShowRedeemInterestDialog] = useState(false);
    const [showRedeemExtraInterestDialog, setShowRedeemExtraInterestDialog] = useState(false);
    const [quantity, setQuantity] = useState('0');
    const isLoading = useAppSelector(state => state.longStake.loading);
    const currentBlock = useAppSelector((state: RootState) => {
        return state.app.currentBlock;
    });

    const vestingPeriod = useMemo(() => {
        if (currentBlock && stake?.expiry) {
            console.log('stake.expiry:', stake.expiry);
            return prettyVestingPeriod(currentBlock, stake.expiry);
        }
        return '--';
    }, [currentBlock, stake]);

    const pendingTransactions = useAppSelector(state => {
        return state.pendingTransactions;
    });

    const warmupLocked = useMemo(() => {
        if (currentBlock && stake?.warmup) {
            return stake.warmup > currentBlock;
        }
        return true;
    }, [currentBlock, stake]);


    const setMaxInterest = (set: any) => {
        set(trim(Number(formatUnits(stake.all - stake.principal, 9)), 4));
    }
    const onRedeemInterest = async (quantity: any) => {
        await dispatch(redeemInterest({ address, networkID: chainID, provider, stake, amount: quantity }));
        setShowRedeemInterestDialog(false);
    }

    const setMaxExtraInterest = (set: any) => {
        set(trim(Number(stake.longStakeReward), 4));
    }

    const onRedeemExtraInterest = async (quantity: any) => {
        console.log('onRedeemExtraInterest:', quantity);
        await dispatch(redeemExtraInterest({ address, networkID: chainID, provider, stake, amount: quantity }));
        setShowRedeemExtraInterestDialog(false);
    }

    const dispatch = useDispatch();
    const { address, chainID, provider } = useWeb3Context();
    const onRedeem = ({ autostake, id, type }: any) => {
        console.log('redeem:', stake);
        console.log('autostake:', autostake);
        console.log('id:', id);
        console.log('type:', type);
    }

    const handleRedeemPrincipal = () => {
        dispatch(redeemPrincipal({ address, networkID: chainID, provider, stake, amount: quantity }));
    }
    
    const getSecondsToWarmup = (warmup:any)=>{
        return 2*Number(BigInt(warmup)-BigInt(currentBlock||(0)));
    }

    return (
        <Box style={{ marginBottom: "30px" }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Box >
                <Box>
                    <Typography
                        sx={{
                            color: "#C7C8CC",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        }}
                    >
                        {stake.type}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Typography
                    sx={{
                        color: "#868b8f",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Claimable</Trans>
                </Typography>
                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    {stake.pendingPayout ? trim(stake.pendingPayout, 4) : <Skeleton width={100} />}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Typography
                    sx={{
                        color: "#868b8f",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Pending</Trans>
                </Typography>
                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    {stake.all ? trim(Number(formatUnits(stake.all, 9)), 4) : <Skeleton width={100} />}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: "20px" }}>
                <Typography
                    sx={{
                        color: "#868b8f",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Fully Vested</Trans>
                </Typography>
                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    {vestingPeriod}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Interest</Trans>
                </Typography>
                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        ml: 'auto', mr: 2
                    }}
                >
                    {stake.all ? trim(Number(formatUnits(stake.all - stake.principal, 9)), 4) : <Skeleton width={100} />}
                </Typography>

                <LoadingButton
                    variant="contained"
                    sx={{
                        height: "32px",
                        borderRadius: "30px",
                        border: "1.3px solid #B50C05",
                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                    }}
                    loadingPosition="end"
                    endIcon={<></>}
                    loading={isLoading}
                    onClick={() => {
                        setShowRedeemInterestDialog(true);
                        // onRedeemInterest({ item: bond, autostake: true, id: bond.id, type: "redeem_bond" })
                    }
                    }
                    disabled={isPendingTxn(pendingTransactions, "redeem_bond") || (stake.all - stake.principal) <= 0.0 || warmupLocked}
                >
                    <Typography
                        sx={{
                            color: "#FFF",
                            fontFamily: "Sometype Mono, sans-serif",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        }}
                    >
                        {warmupLocked ? t`Warmup Locked` : `${txnButtonText(pendingTransactions, "redeem_bond", t`Claim Interest`)}`}
                    </Typography>
                </LoadingButton>

            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 1 }}>
                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Long-term Interest</Trans>
                </Typography>
                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        ml: 'auto', mr: 2
                    }}
                >
                    {!isNaN(Number(stake.longStakeReward)) ? trim(Number(stake.longStakeReward), 4) : <Skeleton width={100} />}
                </Typography>
                {<LoadingButton
                    variant="contained"
                    sx={{
                        height: "32px",
                        borderRadius: "30px",
                        border: "1.3px solid #B50C05",
                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                    }}
                    loadingPosition="end"
                    endIcon={<></>}
                    loading={isLoading}
                    onClick={() => setShowRedeemExtraInterestDialog(true)}
                    disabled={isPendingTxn(pendingTransactions, "redeem_bond") || Number(stake.longStakeReward) == 0.0 || warmupLocked}
                >
                    <Typography
                        sx={{
                            color: "#FFF",
                            fontFamily: "AGaramond LT",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        }}
                    >
                        {warmupLocked ? t`Warmup Locked` : `${txnButtonText(pendingTransactions, "redeem_extrainterest", t`Claim`)}`}
                    </Typography>
                </LoadingButton>}

            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>


                {
                    currentBlock&&(warmupLocked ? <CountDownButton period={getSecondsToWarmup(stake.warmup)} onTimeOut={()=>{}} title={t`Warmup Locked`}/> :
                        <LoadingButton
                            variant="contained"
                            sx={{
                                mt: 1,
                                height: "32px",
                                borderRadius: "30px",
                                width: "100%",
                                border: "1.3px solid #B50C05",
                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                            }}
                            loadingPosition="end"
                            endIcon={<></>}
                            loading={isLoading}
                            disabled={isLoading || stake.pendingPayout == 0.0 || warmupLocked}
                            onClick={handleRedeemPrincipal}
                        >
                            <Typography
                                sx={{
                                    color: "#FFF",
                                    fontFamily: "AGaramond LT",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                }}
                            >
                                {txnButtonText(pendingTransactions, "redeem_bond", t`Claim`)}
                            </Typography>
                        </LoadingButton>)}
            </Box>
            <Box
                sx={{
                    mt: 2,
                    width: "100%",
                    height: "1px",
                    background: "#0b0c0a",
                    marginBottom: "10px",
                    boxShadow: "0px -1px 0px 0px rgba(83, 89, 102, 0.60)",
                }}
            />
            <AmountDialog open={showRedeemInterestDialog} onClose={() => setShowRedeemInterestDialog(false)}
                onRedeem={onRedeemInterest} onMax={setMaxInterest} isLoading={isLoading} />

            <AmountDialog open={showRedeemExtraInterestDialog} onClose={() => setShowRedeemExtraInterestDialog(false)}
                onRedeem={onRedeemExtraInterest} onMax={setMaxExtraInterest} isLoading={isLoading} />
        </Box>

    )
}

const LongTermStake = () => {
    const { isSmallScreen } = useMobile();
    const [quantity, setQuantity] = useState('0');
    const isLoading = useAppSelector(state => state.longStake.loading);
    const pendingTransactions = useAppSelector(state => {
        return state.pendingTransactions;
    });
    const { provider, address, connected, connect, chainID } = useWeb3Context();
    const longStakes = useAppSelector(state => state.longStake.longStakes);
    console.log('longStakes records:', longStakes);

    const ohmBalance = useAppSelector(state => state.account.balances.ohm);
    const stakesOnChain = useAppSelector(state => state.longStake.stakes);
    console.log('stakesOnChain:', stakesOnChain);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getLongStakes({ address, networkID: chainID, provider }));
    }, [address, chainID]);


    useEffect(() => {
        dispatch(getLongStakeStatus({ address, networkID: chainID, provider }));
    }, [address, chainID]);

    return (
        <PullToRefresh onRefresh={() => { }}>

            <Zoom in={true} timeout={1000}>
                <Box
                    sx={{
                        px: isSmallScreen ? 1.875 : 0,
                        pt: isSmallScreen ? 2.5 : 0,
                    }}
                >
                    <Paper
                        className={`ohm-card linear-card`}
                        sx={{
                            mb: 3.125,
                        }}
                    >

                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                px: isSmallScreen ? 1.875 : 3.75,
                                py: 2.5,
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                // border: "2px solid #434952",
                                // background: "pink",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                            }}
                        >

                            <Typography variant="h1"
                                sx={{
                                    color: "#c1c2c8",
                                    fontFamily: "AGaramond LT",
                                    fontSize: isSmallScreen ? "24px" : "32px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    m: 2
                                }}
                            ><Trans>Long-term Stake</Trans></Typography>
                            {
                                (stakesOnChain.length <= 0 && isLoading) && <Skeleton width="100%" height="200px" />
                            }
                            {
                                stakesOnChain?.map((stake: any, idx: number) => {
                                    return (
                                        <Box key={'stake_' + idx}>
                                            <LongStakeCard stake={stake} />
                                        </Box>
                                    )
                                })
                            }

                        </Box>
                    </Paper>
                </Box>
            </Zoom>

            <Zoom in={true} timeout={1000}>
                <Box sx={{ p: 2 }}>
                    <Paper className={`ohm-card linear-card`}
                        sx={{
                            mb: 3.125,
                        }}>
                        <Box sx={{ width: '100%', p: 2 }}>
                            <Box>
                                <Typography variant="h4" sx={{
                                    color: "#c1c2c8",
                                    fontFamily: "AGaramond LT",
                                    fontSize: isSmallScreen ? "24px" : "32px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    m: 2
                                }}>
                                    <Trans>Long-term Stake Records</Trans>
                                </Typography>
                            </Box>
                            {
                                longStakes.map((longStake: any, idx: number) => {
                                    return (
                                        <Box
                                            key={'soabk_' + idx}
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <LongStakeCardData stake={longStake} stakeIndex={idx} />

                                        </Box>
                                    )
                                })
                            }
                        </Box>

                    </Paper>
                </Box>
            </Zoom>

        </PullToRefresh>
    )

}

export default LongTermStake;