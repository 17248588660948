import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/AccountSlice";
import turbineReducer from "./slices/TurbineSlice";
import mintReducer from "./slices/MintSlice";
import communityReducer from "./slices/CommunitySlice";
import bondingReducer from "./slices/BondSlice";
import appReducer from "./slices/AppSlice";
import pendingTransactionsReducer from "./slices/PendingTxnsSlice";
import messagesReducer from "./slices/MessagesSlice";
import swapReducer from "./slices/SwapThunk";
import legacyReducer from "./slices/LegacySlice";
import stakeExReducer from './slices/StakeExSlice';
import ecosystemReducer from './slices/EcosystemSlice';
import longStakeSlice from "./slices/LongStakeSlice";
import daoReducer from "./slices/DaoSlice";
// reducers are named automatically based on the name field in the slice
// exported in slice files by default as nameOfSlice.reducer

const store = configureStore({
  reducer: {
    //   we'll have state.account, state.bonding, etc, each handled by the corresponding
    // reducer imported from the slice file
    account: accountReducer,
    bonding: bondingReducer,
    app: appReducer,
    pendingTransactions: pendingTransactionsReducer,
    messages: messagesReducer,
    turbine: turbineReducer,
    mint: mintReducer,
    community: communityReducer,
    swap: swapReducer,
    legacy: legacyReducer,
    stakeex: stakeExReducer,
    longStake: longStakeSlice,
    ecosystem: ecosystemReducer,
    dao:daoReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
