import { useCallback, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Social from "./Social";
import externalUrls from "./externalUrls";
import { ReactComponent as IDOIcon } from "../../assets/icons/ido.svg";
import { ReactComponent as SwapIcon } from "../../assets/icons/swapIcon.svg";
import { ReactComponent as StakeIcon } from "../../assets/icons/stake.svg";
import { ReactComponent as BondIcon } from "../../assets/icons/bond.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as OlympusIcon } from "../../assets/icons/olympus-nav-header.svg";
import { ReactComponent as PoolTogetherIcon } from "../../assets/icons/33-together.svg";
import { Trans, t } from "@lingui/macro";
import { trim, shorten } from "../../helpers";
// import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import useBonds from "../../hooks/Bonds";
import { Paper, Link, Box, Typography, SvgIcon } from "@mui/material";
import { Skeleton } from "@mui/material";
import "./sidebar.scss";
import { useIsPath, useMobile, useWeb3Context } from "../../hooks";
import { useChainId } from "wagmi";
import MenuDrop from "../TopBar/MenuDrop";
import { addresses, TOKEN_DECIMALS } from "src/constants";



const NoDropMenu = () => {
    const [currentTitle, setCurrentTitle] = useState("Origin");
    const { chainID } = useWeb3Context();
    const isHome = useIsPath("/home");
    const isDashboard = useIsPath("/dashboard");
    const isBond = useIsPath("/bonds");
    const isStake = useIsPath("/stake");
    const isReward = useIsPath("/reward");
    const isCommunity = useIsPath("/community");
    const isInvite = useIsPath("/invite");
    const isTurbine = useIsPath("/turbine");
    const isMint = useIsPath("/mint");
    const isWebKey = useIsPath("/webkey");
    const isEcosystem = useIsPath("/ecosystem");
    const isLPBond = useIsPath("/stakelpbonds")
    const isStableBond = useIsPath("/stakestablebonds")
    const isLongTermStake = useIsPath("/long-term-stake")
    const isVote = useIsPath("/vote");
    // const isSwap = useIsPath("/swap");

    useEffect(() => {
        setCurrentTitle(
            isHome
                ? t`Home`
                : isDashboard
                    ? t`Dashboard`
                    : isBond
                        ? t`Bond`
                        : isStake
                            ? t`Stake`
                            : isReward
                                ? t`Reward Pool`
                                : isCommunity
                                    ? t`Community`
                                    : isInvite
                                        ? t`Invite`
                                        : isTurbine
                                            ? t`Turbine`
                                            : isMint
                                                ? t`Mint`
                                                : isEcosystem
                                                    ? t`Ecosystem`
                                                    : "Origin",
        );
    }, [
        isHome,
        isDashboard,
        isBond,
        isStake,
        isReward,
        isCommunity,
        isInvite,
        isTurbine,
        isMint,
        isWebKey,
        // isSwap,
        setCurrentTitle,
    ]);
    return (
        <Box
            component="div"
            sx={{
                alignItems: 'center',
                borderRadius: "8px",
                background: "linear-gradient(138deg, #32373A 2.23%, #131416 97.6%)",
                boxShadow: "0px 0px 4px 0px #33383C inset",
            }}
        >

            <Link href="/#/home" onClick={() => setCurrentTitle("Home")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isHome ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isHome ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Home</Trans>
                    </Typography>
                </Box>
            </Link>


            <Link href="/#/dashboard" onClick={() => setCurrentTitle("Dashboard")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isDashboard ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isDashboard ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Dashboard</Trans>
                    </Typography>
                </Box>
            </Link>


            <Link href="/#/stake" onClick={() => setCurrentTitle("Stake")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isStake ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isStake ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Stake</Trans>
                    </Typography>
                </Box>
            </Link>
            <Link href="/#/long-term-stake" onClick={() => setCurrentTitle("Long-term Stake")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isLongTermStake ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isLongTermStake ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Long-term Stake</Trans>
                    </Typography>
                </Box>
            </Link>



            <Link href="/#/bonds" onClick={() => setCurrentTitle("Bond")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isBond ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isBond ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Bond</Trans>
                    </Typography>
                </Box>
            </Link>

            <Link href="/#/stakelpbonds" onClick={() => setCurrentTitle("LP Bond")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isLPBond ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isLPBond ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Long-term LP Bond</Trans>
                    </Typography>
                </Box>
            </Link>

            <Link href="/#/stakestablebonds" onClick={() => setCurrentTitle("Stable Bond")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isStableBond ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isStableBond ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Long-term Stable Bond</Trans>
                    </Typography>
                </Box>
            </Link>

            <Link href="/#/reward" onClick={() => setCurrentTitle("Reward")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isReward ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isReward ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Reward Pool</Trans>
                    </Typography>
                </Box>
            </Link>


            <Link href="/#/community" onClick={() => setCurrentTitle("Community")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isCommunity ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isCommunity ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Community</Trans>
                    </Typography>
                </Box>
            </Link>


            <Link href="/#/invite" onClick={() => setCurrentTitle("Invite")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isInvite ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isInvite ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Invite</Trans>
                    </Typography>
                </Box>
            </Link>


            <Link href="/#/turbine" onClick={() => setCurrentTitle("Turbine")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isTurbine ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isTurbine ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Turbine</Trans>
                    </Typography>
                </Box>
            </Link>
            <Link href="/#/vote" onClick={() => setCurrentTitle("Dao Vote")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isVote ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isVote ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Dao Vote</Trans>
                    </Typography>
                </Box>
            </Link>


            <Link href="/#/mint" onClick={() => setCurrentTitle("Mint")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isMint ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isMint ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Mint</Trans>
                    </Typography>
                </Box>
            </Link>

            <Link href="/#/webkey" onClick={() => setCurrentTitle("Stake")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isWebKey ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isWebKey ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Mobile Phone</Trans>
                    </Typography>
                </Box>
            </Link>

            <Link href="/#/ecosystem" onClick={() => setCurrentTitle("Stake")}>
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isEcosystem ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
                    <Typography
                        sx={{
                            color: isEcosystem ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>DOGE</Trans>
                    </Typography>
                </Box>
            </Link>

            <Link
                href={`https://quickswap.exchange/#/swap?chain=mainnet&inputCurrency=${addresses[chainID].USDT_ADDRESS}&outputCurrency=${addresses[chainID].OHM_ADDRESS}`}
                target="_blank"
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1 }}>
                    {/* <Link href="/#/swap" onClick={() => setCurrentTitle("Swap")}> */}
                    <Typography
                        sx={{
                            color: "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "16px", lineHeight: "27px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                        }}
                    >
                        <Trans>Swap</Trans>
                    </Typography>
                </Box>
            </Link>

        </Box>
    )
}


function NavContent({ mobileOpen, handleDrawerToggle }) {
    const { isSmallScreen } = useMobile();
    const [isActive] = useState();
    // const { chainID } = useWeb3Context();
    const chainID = useChainId();
    const { bonds } = useBonds(chainID);
    const isHome = useIsPath("/home");
    const isDashboard = useIsPath("/dashboard");
    const isBond = useIsPath("/bonds");
    const isStake = useIsPath("/stake");
    const isReward = useIsPath("/reward");
    const isCommunity = useIsPath("/community");
    const isInvite = useIsPath("/invite");
    const isTurbine = useIsPath("/turbine");
    const checkPage = useCallback((match, location, page) => {
        const currentPath = location.pathname.replace("/", "");
        if (currentPath.indexOf("dashboard") >= 0) {
            return true;
        }
        if (currentPath.indexOf("stake") >= 0) {
            return true;
        }
        if (currentPath.indexOf("ido") >= 0) {
            return true;
        }
        if (currentPath.indexOf("swap") >= 0) {
            return true;
        }
        if (currentPath.indexOf("home") >= 0) {
            return true;
        }
        if (currentPath.indexOf("unlock") >= 0) {
            return true;
        }
        if (currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) {
            return true;
        }
        if (currentPath.indexOf("33-together") >= 0) {
            return true;
        }
        return false;
    }, []);
    let history = useHistory();
    const goPancakeSwap = () => {
        // window.open(
        //   `https://quickswap.exchange/#/swap?chain=mainnet&inputCurrency=${addresses[chainID].USDT_ADDRESS}&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
        //   "_blank",
        // );
        // window.open(`https://quickswap.exchange/#/swap`, "_blank");
        // window.open(
        //   `https://pancakeswap.finance/swap?chain=${chainID === 56 ? "bsc" : "bscTestnet"}&inputCurrency=${
        //     addresses[chainID].USDT_ADDRESS
        //   }&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
        //   "_blank",
        // );
        history.push("/swap");
        // window.open('/swap','_blank')
    };

    const goV2Version = () => {
        window.open(`https://origindefi.io`, "_blank");
    };

    return (
        <Paper className="dapp-sidebar linear-card">
            <Box
                className="dapp-sidebar-inner"
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                sx={{
                    width: "100%",
                    height: "100%",
                    background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                }}
            >
                <div className="dapp-menu-top">
                    <Box className="branding-header">
                        <Link href="https://origindao.io/" target="_blank">
                            <SvgIcon
                                color="primary"
                                component={OlympusIcon}
                                viewBox="0 0 81 88"
                                style={{ minWidth: "81px", minHeight: "88px", width: "81px" }}
                            />
                        </Link>

                        {/* {address && (
              <div className="wallet-link">
                <Link href={`https://bscscan.io/address/${address}`} target="_blank">
                  {shorten(address)}
                </Link>
              </div>
            )} */}
                    </Box>

                    <Box alignItems="flex-start" className="dapp-menu-links">
                        <Box className="dapp-nav" id="navbarNav" sx={{ width: "100%", px: 1.25 }}>
                            <Box sx={{ height: "44px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                <Box
                                    sx={{
                                        px: 1,
                                        py: 0.25,
                                        ml: 1.25,
                                        borderRadius: "14px",
                                        border: "1px solid #E93F25",
                                        height: "30px",
                                        width: "80px",
                                    }}
                                    onClick={goPancakeSwap}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography
                                        sx={{
                                            background: "linear-gradient(287deg, #E93F25 4.37%, #FF7F43 94.15%)",
                                            backgroundClip: "text",
                                            WebkitBackgroundClip: "text",
                                            WebkitTextFillColor: "transparent",
                                            fontFamily: "Neue Haas Grotesk Text Pro",
                                            fontSize: "13px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        <Trans>Buy</Trans>
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        px: 1,
                                        py: 0.25,
                                        ml: 1.25,
                                        borderRadius: "14px",
                                        border: "1px solid #E93F25",
                                        height: "30px",
                                        width: "80px"
                                    }}
                                    onClick={goV2Version}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography
                                        sx={{
                                            background: "linear-gradient(287deg, #E93F25 4.37%, #FF7F43 94.15%)",
                                            backgroundClip: "text",
                                            WebkitBackgroundClip: "text",
                                            WebkitTextFillColor: "transparent",
                                            fontFamily: "Neue Haas Grotesk Text Pro",
                                            fontSize: "13px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        <Trans>V2</Trans>
                                    </Typography>
                                </Box>
                            </Box>
                            <NoDropMenu />
                        </Box>
                    </Box>
                </div>

            </Box>
        </Paper>
    );
}

export default NavContent;
